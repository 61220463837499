import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";

const API_CONFIG = {
  GET_WORKSPACE_FILES: {
    method: "GET",
    data: {},
    url: "/v0/workspace/status",
  },
};

const InitialState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
};

export const getWorkspaceListApiCall = createAsyncThunk(
  "get/workspace-list",
  async ({ searchKey }) => {
    try {
      const apiPayload = {
        ...API_CONFIG.GET_WORKSPACE_FILES,
        params: {
          module_status: searchKey,
        },
      };
      const response = await apiClient(apiPayload);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getWorkspaceListSlice = createSlice({
  name: "get/workspace-list",
  initialState: InitialState,
  reducers: {
    resetCount: (state) => {
      state.data = null;
      state.error = null;
      state.isError = false;
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkspaceListApiCall.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
        state.isError = false;
        state.flag = false;
      })
      .addCase(getWorkspaceListApiCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.isError = false;
        state.flag = true;
      })
      .addCase(getWorkspaceListApiCall.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
        state.isError = true;
        state.flag = false;
      });
  },
});

export const getWorkspaceListAction = getWorkspaceListSlice.actions;

const getWorkspacePageReducers = {
  getWorkspaceListData: getWorkspaceListSlice.reducer,
};

export default getWorkspacePageReducers;
