// import { commonReducer } from "../../../../logic";
import foldersSectionsReducer from "../component/SummarySidebar/logic";
import getHomePageReducers from "../pages/Home/logic";
import userLoginReducer from "../pages/Login/logic";
import newsummarizedDataReducers from "../pages/NewSummarization/logic";
import searchFolderReducers from "../pages/SearchFolder/logic";
// import summarizedDataReducers from "../pages/Summarization/logic";
import getWorkspacePageReducers from "../pages/WorkSpace/logic";
export const combinedReducers = {
  ...getHomePageReducers,
  ...getWorkspacePageReducers,
  ...searchFolderReducers,
  ...foldersSectionsReducer,
  ...newsummarizedDataReducers,
  ...userLoginReducer,
  // ...commonReducer
  
};
