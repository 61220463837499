import { Menu, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  UploadOutlined,
  CodeOutlined,
  EditOutlined
} from "@ant-design/icons";
// import { dummySidebarData } from "./demoData";
import "./newSummarySidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFoldersSectionsApiCall } from "../SummarySidebar/logic";
// import { getFoldersSectionsApiCall } from "./logic";
// import { getModulesSectionProcessApiCall, getModulesSectionsApiCall } from "../../pages/NewSummarization/logic";
import { useLocation } from "react-router-dom";
import { all } from "axios";
import { responseData } from "../../pages/NewSummarization/summaryResponse";
import { getModulesSectionProcessApiCall, getModulesSectionsApiCall } from "../../pages/NewSummarization/logic";

const FolderOrFileIcon = ({ item }) => {
  if (item?.children && !item?.value) {
    return <img src="/images/folder-icon.svg" alt="folder" className="folder-icon" />;
  } 
};

const NewSummarySideBar = ({showLoader,setShowLoader, setHandleKey, handleKey,defaultOpenKey,setDefaultOpenKey }) => {
  const dispatch=useDispatch();
  const location = useLocation();
  // console.log(String(defaultOpenKey))
  const urlSearchParams = new URLSearchParams(location.search);
  const identifier_name = urlSearchParams.get("drug_name")
  const { data: getModuleSectionData, loading: getModuleSectionDataLoading } = useSelector((state) => state?.getModulesSectionsData)
const [generating,setGenerating]=useState(false);
const [fileName,setFileName]=useState();
  const {data:folderStructure}=useSelector((state)=>state?.getFolderStructureData)
  useEffect(()=>{
    dispatch(getFoldersSectionsApiCall({identifier_name}));
  },[])

  // useEffect(()=>{console.log(defaultOpenKey)},[defaultOpenKey])

  const handleMenuClick = (item) => {
    if (item?.name && item?.name !== fileName) {
      setFileName(item?.name);
      setGenerating(true);
      setShowLoader(true); // This should work if `setShowLoader` is correctly passed
      dispatch(getModulesSectionProcessApiCall({ searchText: identifier_name, file_name: item?.name }));
      dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: item?.name }));
    }
  
    setHandleKey(item?.id);
    setDefaultOpenKey(String(item?.id) || defaultOpenKey);
  };


  
 

  useEffect(() => {
  if (getModuleSectionData?.progress_percent < 100) {

    const intervalId = setInterval(() => {
      setShowLoader(true);
      dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: fileName }));
    }, 300000);
    
    return () => clearInterval(intervalId); // Cleanup on unmount or when dependencies change
  } else {
    setGenerating(false);
  }
}, [generating, getModuleSectionData?.project_status, fileName, identifier_name, dispatch]);

const SidebarItem = ({ item }) => {
  // Helper function to recursively render the responseValue items
  const renderResponseValue = (responseValue) => (
    <ul style={{ paddingLeft: '20px' }}>
      {responseValue.map((response) => (
        <li key={response.id} className='sections-2' onClick={(e)=>{handleMenuClick(response);e.stopPropagation()}}>
          {response.value} 

          {response.children && response.children.length > 0 && (
            <ul style={{ paddingLeft: '20px' }}>
              {response.children.map((child) => (
                <li key={child.id}
                // className={String(child.id)=== String(defaultOpenKey) ? `subkeys-active` :`subkeys`} 
              //   style={
              //     child.id &&  {
              //     backgroundColor:String(child.id)=== String(defaultOpenKey) ? '#e6f4ff' : 'transparent',
              //     // color: item.id === defaultOpenKey ? 'white' : 'black',
              //     padding:String(child.id)=== String(defaultOpenKey) ? '10px' : '0px',
                  
              //     cursor: 'pointer'
              //   }
              // }  
               onClick={(e)=>{handleMenuClick(child);e.stopPropagation()}}>
                  {child?.value}
                  {child.children && child.children.length > 0 && (
                    <ul style={{ paddingLeft: '20px' }}>
                      {child.children.map((grandchild) => (
                        <li 
                        className={String(grandchild.id)=== String(defaultOpenKey) ? `subkeys-active` :`subkeys`} 

                        style={
                            grandchild.id &&  {
                            backgroundColor:String(grandchild.id)=== String(defaultOpenKey) ? '#e6f4ff' : 'transparent',
                            // color: item.id === defaultOpenKey ? 'white' : 'black',
                            padding:String(grandchild.id)=== String(defaultOpenKey) &&'15px',

                            cursor: 'pointer'
                          }
                        }  
                        // id={grandchild?.id}
                        key={grandchild.id} onClick={(e)=>{handleMenuClick(grandchild);e.stopPropagation()}}>{grandchild?.value}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );

  // Helper function to render the folder items (children of the current folder)
  const renderChildren = (children) => (
    <ul style={{ paddingLeft: '20px' }}>
      {children.map((child) => (
        <SidebarItem key={child.name} item={child} onClick={(e)=>{handleMenuClick(child);e.stopPropagation()}}/>
      ))}
    </ul>
  );

  return (
    <div>
      {/* Render the current file or folder name */}
      <ul style={{ listStyleType: 'none', paddingLeft: item.children && item.children.length > 0 ? '20px' : '0' }}>
        <li  onClick={(e)=>{handleMenuClick(item);e.stopPropagation()}}>{item.name}</li> {/* Render the current item (file/folder name) */}

        {/* Render responseValue sections if they exist */}
        {item.responseValue && item.responseValue.length > 0 && renderResponseValue(item.responseValue)}

        {/* Render children if they exist and it's a folder (not a responseValue) */}
        {item.children && item.children.length > 0 && !item.responseValue && renderChildren(item.children)}
      </ul>
    </div>
  );
};


return (
<div className='new-summary-sidebar' >
<ul style={{ paddingLeft: '0' }}>
  {/* Iterating over responseData to render each folder */}
  {folderStructure?.files?.m2?.map((folder) => (
    <div key={folder?.folder_name}>
      <div className='section-1'>{folder?.folder_name}</div> {/* Render folder name */}
      <SidebarItem key={folder?.folder_name} item={folder} />
    </div>
  ))}
</ul>
</div>
);
};

export default NewSummarySideBar;
