import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { toast } from "react-toastify";

const API_CONFIG = {
 
  POST_GENERATE_SECTION: {
    method: "POST",
    data: {},
    url: "/v0/module/generateSection",
  },
  GET_ALL_SECTIONS:{
    method: "GET",
    data: {},
    url: "/v0/module/getAllSections",
  },
  GET_MODULES_SECTIONS:{
    method: "GET",
    data: {},
    url: "/v0/module/getModuleSections",
  },
  POST_EDIT_SECTION: {
    method: "POST",
    data: {},
    url: "/v0/module/editSection",
  },
  GET_VERSIONS_SECTIONS: {
    method: "GET",
    data: {},
    url: "/v0/module/getSectionVersions",
  },
  LOCK_SECTIONS: {
    method: "GET",
    data: {},
    url: "/v0/module/lockSection",
  },
 
};

const InitialState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
};



// generate section api call on prompt change
export const generateSectionApiCall = createAsyncThunk(
    "post/generate-section",
    async ({dataToSend,identifier_name,section_name,file_name}) => {

      try {
        const apiPayload = {
          ...API_CONFIG.POST_GENERATE_SECTION,
          params:{
            identifier_name,section_name,file_name
          }
        
        };
        apiPayload.data =dataToSend;

        
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );

  const generateSectionSlice = createSlice({
    name: "post/generate-section",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(generateSectionApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(generateSectionApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(generateSectionApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const generateSectionAction = generateSectionSlice.actions;
  
  // get all sections api
  export const getAllSectionsApiCall = createAsyncThunk(
    "get/get-all-sections",
    async ({searchText}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_ALL_SECTIONS,
          params:{
            identifier_name:searchText
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const getAllSectionsSlice = createSlice({
    name: "get/get-all-sections",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getAllSectionsApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(getAllSectionsApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(getAllSectionsApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const getAllSectionsAction = getAllSectionsSlice.actions;



  // modules sections api
  export const getModulesSectionsApiCall = createAsyncThunk(
    "get/get-modules-sections",
    async ({searchText,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_MODULES_SECTIONS,
          params:{
            identifier_name:searchText,
            file_name:file_name,
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        localStorage.clear();
        toast.error("Session Expired")
        setTimeout(()=>{
    window.location.reload();
        },[500])
        return error;
      }
    }
  );
  
  const getModulesSectionsSlice = createSlice({
    name: "get/get-modules-sections",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = false;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getModulesSectionsApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(getModulesSectionsApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(getModulesSectionsApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const getModulesSectionsAction = getModulesSectionsSlice.actions;


  // modules sections process api
  export const getModulesSectionProcessApiCall = createAsyncThunk(
    "get/get-modules-sections-process",
    async ({searchText,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_MODULES_SECTIONS,
          params:{
            identifier_name:searchText,
            file_name:file_name,
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        localStorage.clear();
        toast.error("Session Expired")
        setTimeout(()=>{
    window.location.reload();
        },[500])
        return error;
      }
    }
  );
  
  const getModulesSectionProcessSlice = createSlice({
    name: "get/get-modules-sections-process",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = false;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getModulesSectionProcessApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(getModulesSectionProcessApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(getModulesSectionProcessApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const getModulesSectionProcessAction = getModulesSectionProcessSlice.actions;





  //Api for edit section

  export const editSectionApiCall = createAsyncThunk(
    "post/edit-section",
    async ({dataToSend,identifier_name,section_name,file_name}) => {

      try {
        const apiPayload = {
          ...API_CONFIG.POST_EDIT_SECTION,
          params:{
            identifier_name,section_name,file_name
          }
        
        };
        apiPayload.data =dataToSend;

        
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );

  const editSectionSlice = createSlice({
    name: "post/edit-section",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(editSectionApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(editSectionApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(editSectionApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const editSectionAction = editSectionSlice.actions;
  

  // Api for Version List
  //Api for version selection
  export const selectVersionListApiCall = createAsyncThunk(
    "get/select-version-list",
    async ({  identifier_name,section_name,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_VERSIONS_SECTIONS,
          params:{
            identifier_name,section_name,file_name
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const selectVersionListSlice = createSlice({
    name: "get/select-version-list",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(selectVersionListApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(selectVersionListApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(selectVersionListApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const selectVersionListAction = selectVersionListSlice.actions;


  //Api for version selection
  export const selectVersionApiCall = createAsyncThunk(
    "get/select-version",
    async ({  identifier_name,section_name,version,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_VERSIONS_SECTIONS,
          params:{
            identifier_name,section_name,version,file_name
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const selectVersionSlice = createSlice({
    name: "get/select-version",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(selectVersionApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(selectVersionApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(selectVersionApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const selectVersionAction = selectVersionSlice.actions;


  // Api for version 1 Data
  export const selectVersion1ApiCall = createAsyncThunk(
    "get/select-version1",
    async ({  identifier_name,section_name,version,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_VERSIONS_SECTIONS,
          params:{
            identifier_name,section_name,version,file_name
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const selectVersion1Slice = createSlice({
    name: "get/select-version1",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(selectVersion1ApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(selectVersion1ApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(selectVersion1ApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const selectVersion1Action = selectVersion1Slice.actions;



  // Api call for version 2 data

  export const selectVersion2ApiCall = createAsyncThunk(
    "get/select-version2",
    async ({  identifier_name,section_name,version,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_VERSIONS_SECTIONS,
          params:{
            identifier_name,section_name,version,file_name
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const selectVersion2Slice = createSlice({
    name: "get/select-version2",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(selectVersion2ApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(selectVersion2ApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(selectVersion2ApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const selectVersion2Action = selectVersion2Slice.actions;




  // Api call for lock sections
  export const lockSectionsApiCall = createAsyncThunk(
    "get/lock-sections",
    async ({identifier_name,section_name,file_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.LOCK_SECTIONS,
          params:{
            identifier_name,section_name,file_name
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );
  
  const lockSectionsSlice = createSlice({
    name: "get/lock-sections",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(lockSectionsApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(lockSectionsApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(lockSectionsApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const lockSectionsAction = lockSectionsSlice.actions;





const newsummarizedDataReducers = {
  uploadFileData:generateSectionSlice.reducer,
  modulesProcessData:getModulesSectionProcessSlice.reducer,
  getAllSectionsData:getAllSectionsSlice.reducer,
  getModulesSectionsData:getModulesSectionsSlice.reducer,
  sectionVersionsData:selectVersionSlice.reducer,
  sectionVersionList:selectVersionListSlice.reducer,
  section1CompareData:selectVersion1Slice.reducer,
  section2CompareData:selectVersion2Slice.reducer,
  lockSectionsData:lockSectionsSlice.reducer,
  editSectionData:editSectionSlice.reducer,
};

export default newsummarizedDataReducers;
