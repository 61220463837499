import { deleteData, getData, setData } from "./storageService";

export const clearSession = () => {
  deleteData();
};

export const addSession = (session) => {
  setData("isLoggedIn", true);
  setData(`Token`,`Bearer ${session.token}`);
  setData('username',session?.user?.full_name)
};

export const checkIfLogin = () => getData("isLoggedIn") || false;

export const getAuthHeader = () => getData(`Token`);
