import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";
import ColumnGroup from "antd/es/table/ColumnGroup";

const API_CONFIG = {
  GET_MODULE_FILES: {
    method: "GET",
    data: {},
    url: "/v0/home/getFiles",
  },
  POST_UPLOAD_MODULE_FILES: {
    method: "POST",
    data: {},
    url: "/v0/home/uploadModule",
  },
  GET_GENERATE_ALL:{
    method: "GET",
    data: {},
    url: "/v0/home/generateAll",
  }
 
};

const InitialState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
};

export const getModuleFilesApiCall = createAsyncThunk(
  "get/module-files",
  async ({searchText}) => {
    try {
      const apiPayload = {
        ...API_CONFIG.GET_MODULE_FILES,
        params:{
          identifier_name:searchText
        }
      
      };
      const response = await apiClient(apiPayload);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

const getModuleFilesSlice = createSlice({
  name: "get/module-files",
  initialState: InitialState,
  reducers: {
    resetCount: (state) => {
      state.data = null;
      state.error = null;
      state.isError = false;
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getModuleFilesApiCall.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
        state.isError = false;
        state.flag = false;
      })
      .addCase(getModuleFilesApiCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.isError = false;
        state.flag = true;
      })
      .addCase(getModuleFilesApiCall.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
        state.isError = true;
        state.flag = false;
      });
  },
});

export const getModuleFilesAction = getModuleFilesSlice.actions;


// upload file

export const uploadModuleFilesApiCall = createAsyncThunk(
    "post/upload-files",
    async ({formData,identifier_name,module_name}) => {

      try {
        const apiPayload = {
          ...API_CONFIG.POST_UPLOAD_MODULE_FILES,
          params:{
            identifier_name,module_name
          }
        
        };
        apiPayload.data =formData;

        
        const response = await apiClient(apiPayload);
        return response;
      } catch (error) {
        return error;
      }
    }
  );
// export const uploadModuleFilesApiCall = createAsyncThunk(
//     'post/upload-files',
//     async (formData) => {
//       try {
//         const response = await apiClient.post('/upload', formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data', // Ensure proper Content-Type header for FormData
//           },
//         });
//         return response.data; // Assuming response data contains the uploaded files' information
//       } catch (error) {
//         throw Error('Failed to upload files: ' + error.message);
//       }
//     }
//   );
  
  const uploadModuleFilesSlice = createSlice({
    name: "post/upload-files",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(uploadModuleFilesApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(uploadModuleFilesApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(uploadModuleFilesApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const uploadModuleFilesAction = uploadModuleFilesSlice.actions;
  
  
// generateall api

export const generateAllApiCall = createAsyncThunk(
  "get/generate-all",
  async ({searchText,selectedModules}) => {
    const selected_values=Object.keys(selectedModules);
    try {
      const apiPayload = {
        ...API_CONFIG.GET_GENERATE_ALL,
        params: {
          identifier_name: searchText,
          selectedModules
      },
      
      };
      const response = await apiClient(apiPayload);
      return response;
    } catch (error) {
      return error;
    }
  }
);

const generateAllSlice = createSlice({
  name: "get/generate-all",
  initialState: InitialState,
  reducers: {
    resetCount: (state) => {
      state.data = null;
      state.error = null;
      state.isError = false;
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(generateAllApiCall.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
        state.isError = false;
        state.flag = false;
      })
      .addCase(generateAllApiCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.isError = false;
        state.flag = true;
      })
      .addCase(generateAllApiCall.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
        state.isError = true;
        state.flag = false;
      });
  },
});

export const generateAllAction = generateAllSlice.actions;

const searchFolderReducers = {
  getModuleFilesData: getModuleFilesSlice.reducer,
  uploadFileData:uploadModuleFilesSlice.reducer,
  generateAllData:generateAllSlice.reducer,
};

export default searchFolderReducers;
