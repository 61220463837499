import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";

const API_CONFIG = {
  GET_SEARCH_OPTIONS: {
    method: "GET",
    data: {},
    url: "/v0/home/autosuggest",
  },
  CREATE_NEW_PROJECT: {
    method: "POST",
    data: {},
    url: "/v0/home/createNewProject",
  },
};

const InitialState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
};

export const getSearchOptionsApiCall = createAsyncThunk(
  "get/search-options",
  async ({ searchText }) => {
    try {
      const apiPayload = {
        ...API_CONFIG.GET_SEARCH_OPTIONS,
        params: {
            identifier_name: searchText.trim(),
        },
      };
      const response = await apiClient(apiPayload);
      return response?.data?.data;
    } catch (error) {
      return error;
    }
  }
);

const getSearchOptionsSlice = createSlice({
  name: "get/search-options",
  initialState: InitialState,
  reducers: {
    resetCount: (state) => {
      state.data = null;
      state.error = null;
      state.isError = false;
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSearchOptionsApiCall.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
        state.isError = false;
        state.flag = false;
      })
      .addCase(getSearchOptionsApiCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.isError = false;
        state.flag = true;
      })
      .addCase(getSearchOptionsApiCall.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
        state.isError = true;
        state.flag = false;
      });
  },
});

export const getSearchOptionsAction = getSearchOptionsSlice.actions;


// create new project api call
export const createNewProjectApiCall = createAsyncThunk(
  "post/create-new-project",
  async ({ searchText }) => {
    try {
      const apiPayload = {
        ...API_CONFIG.CREATE_NEW_PROJECT,
        params: {
            identifier_name: searchText.trim(),
        },
      };
      const response = await apiClient(apiPayload);
      return response.data?.data;
    } catch (error) {
      return error;
    }
  }
);

const createNewProjectSlice = createSlice({
  name: "post/create-new-project",
  initialState: InitialState,
  reducers: {
    resetCount: (state) => {
      state.data = null;
      state.error = null;
      state.isError = false;
      state.loading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createNewProjectApiCall.pending, (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
        state.isError = false;
        state.flag = false;
      })
      .addCase(createNewProjectApiCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
        state.isError = false;
        state.flag = true;
      })
      .addCase(createNewProjectApiCall.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
        state.isError = true;
        state.flag = false;
      });
  },
});

export const createNewProjectAction = createNewProjectSlice.actions;


const getHomePageReducers = {
  getSearchOptionsData: getSearchOptionsSlice.reducer,
  createNewProjectData:createNewProjectSlice.reducer
};

export default getHomePageReducers;
