import React, { useEffect, useState } from "react";
import "./myworkspace.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWorkspaceListApiCall } from "./logic";
import { StringDiff } from "react-string-diff";

const MyWorkspace = () => {
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("Completed");
  const onChange = (key) => {
    setSearchKey(key);
  };
  const { data: workspaceData } = useSelector(
    (state) => state?.getWorkspaceListData
  );
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWorkspaceListApiCall({ searchKey }));
  }, [searchKey]);
  const demoCompletedData = [
    { file_name: "Afatanib", time: "1 Jan, 2024" },
    { file_name: "Osimertinib", time: "12 Jan, 2024" },
  ];
  const demoDraftData = [{ file_name: "Carbolplatin", time: "12 Feb, 2024" }];
  function formatTimestampToDateCustom(timestamp) {
    const date = new Date(timestamp);
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
  }
  const demoCompleted = () => {
    return (
      <div className="section-completed">
        {workspaceData?.map((e) => {
          return (
            <div className="folder">
              <div className="file-name">
                <img
                  src="/images/file-icon.svg"
                  alt="file-icon"
                  className="file-icon"
                />{" "}
                {e?.identifier_name}
              </div>
              <div>{formatTimestampToDateCustom(e?.created_date_time_ts)}</div>
            </div>
          );
        })}
      </div>
    );
  };
  const demoDraft = () => {
    return (
      <div className="section-draft">
        {workspaceData?.map((e) => {
          return (
            <div className="folder" onClick={()=> navigate(`/summary?drug_name=${e?.identifier_name}`)}>
              <div className="file-name">
                <img
                  src="/images/file-icon.svg"
                  alt="file-icon"
                  className="file-icon"
                />{" "}
                {e?.identifier_name}
              </div>
              <div>{formatTimestampToDateCustom(e?.created_date_time_ts)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const items = [
    {
      key: "Completed",
      label: "Completed",
      children: demoCompleted(),
    },
    {
      key: "Draft",
      label: "Draft",
      children: demoDraft(),
    },
  ];

  const oldValue = ` <p>Hello HI</p>`;
  const newValue = `<div> Helllo</div>`;
  const computeDiff = (oldStr, newStr) => {
    const maxLength = Math.max(oldStr.length, newStr.length);
    let diff = '';

    for (let i = 0; i < maxLength; i++) {
      if (oldStr[i] !== newStr[i]) {
        diff += `<span style="background-color: yellow">${newStr[i] || ''}</span>`;
      } else {
        diff += newStr[i] || '';
      }
    }

    return diff;
  };

  return (
    <div className="my-workspace">
      <div className="my-workspace-content">
        <div className="btn-back" onClick={() => navigate(-1)}>
          <ArrowLeftOutlined className="arrow-icon" />
          Back
        </div>
        <div className="title-search"> My work space</div>
        <div className="tabs-section">
          <Tabs
            defaultActiveKey="Completed"
            items={items}
            onChange={onChange}
          />
        </div>
        {/* <StringDiff oldValue={oldValue} newValue={newValue} splitView /> */}
        {/* <StringDiff oldValue={newValue} newValue={oldValue} splitView /> */}
        <div>
     
    </div>
      </div>
    </div>
  );
};

export default MyWorkspace;
