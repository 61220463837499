import { Tooltip } from "antd";

const ProgressBar = (props) => {
    const { bgcolor, progressStatus,isOpen } = props;
  
    const containerStyles = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
    //   margin: 50
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${progressStatus}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    const labelStyles = {
    //   padding: 5,
    display:'flex',
    justifyContent:"center",
      color: 'white',
      fontWeight: 'bold',
      fontSize:"14px",
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
        <Tooltip  placement="topRight" title={`${Math.round(progressStatus)}%`} open={isOpen} >

          <span style={labelStyles}></span>
          </Tooltip>

        </div>
      </div>
    );
  };
  
  export default ProgressBar;