import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";
import SummarySidebar from "../SummarySidebar";
import { getData } from "../../utils/storageService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NewSummarySideBar from "../NewSummarySideBar";



const Sidebar = ({ setHandleKey, handleKey,defaultOpenKey,setDefaultOpenKey,showLoader,setShowLoader }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [closeEye,setCloseEye]=useState(false);
  const handleClick = (key) => {
    setHandleKey(key);
  };
  const logout=()=>{
    localStorage.clear();
    setTimeout(()=>{
window.location.reload();
  
    },[500])
  }
  const activeCss = {
    backgroundColor: "#DBDBDB",
    color: "#5252fa",
    fontWeight: "bold",
    padding: "10px 10px",
    // Add more styles as needed
  };

  const renderSections = (sections) => {
    return sections.map((section) => (
      <ScrollLink
        key={section.id}
        to={section.id}
        spy={true}
        smooth={true}
        duration={500}
        className={`${section.class} ${
          handleKey === section.id ? "active-section" : ""
        }`}
        onClick={() => handleClick(section.id)}
      >
        <div
          className="header"
          style={handleKey === section.id ? activeCss : {}}
        >
          {section.id} {section.value}
        </div>
        {section.children && renderSections(section.children)}
      </ScrollLink>
    ));
  };

  return (
    <div className="sidebar">
      <div className="title-4" onMouseOverCapture={()=>setCloseEye(true)} onMouseLeave={()=>setCloseEye(false)} onClick={() => {
        if(!!getData("Token") )
          {navigate("/home")}
      else
      {navigate("/")}}} >
       {closeEye ? <img  src="/images/close-eye.svg" alt="logo"/> : <img  src="/images/open-eye.svg" alt="logo"/>}
      </div>
      <div className="horizontal-line" />
      {location?.pathname !== "/summary" ? (
        <div className="container">
          <div className="folder-image">
            <img
              src="/images/workspace-icon.png"
              alt="folder"
              onClick={() => navigate("/my-workspace")}
            />
          </div>
          <div className="user-details">
            <div className="user">
              <div className="details-icon" >{getData(`username`)?.[0]}</div>{getData(`username`)}
            </div>
            {/* <div className="setting">
              <div className="details-icon">
                <img src="/images/setting-icon.svg" alt="folder" />
              </div>
              Settings
            </div> */}
            <div className="setting">
              <div className="details-icon" onClick={logout}>
              {/* <LogoutOutlined /> */}
                <img src="/images/logout.svg" alt="folder" />
              </div>
              Logout
            </div>
          </div>
        </div>
      ) : (
        <div className="container-summary">
          {/* {responseData.map((section) => (
            <ScrollElement
              key={section.id}
              name={section.id}
              className={section.class}
            >
              {renderSections([section])}
            </ScrollElement>
          ))} */}
          <NewSummarySideBar setHandleKey={setHandleKey} handleKey={handleKey} showLoader={showLoader} setShowLoader={setShowLoader} defaultOpenKey={defaultOpenKey} setDefaultOpenKey={setDefaultOpenKey}></NewSummarySideBar>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
