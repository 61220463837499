
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";

const API_CONFIG = {

  POST_USER_REGISTER: {
    method: "POST",
    data: {},
    url: "/v0/auth/register",
  },
  POST_USER_LOGIN: {
    method: "POST",
    data: {},
    url: "/v0/auth/login",
  },
};

const InitialState = {
  loading: false,
  isError: false,
  data: null,
  error: {},
};



// Api for register
export const userRegistrationApiCall = createAsyncThunk(
    "post/user-register",
    async ({userData}) => {

      try {
        const apiPayload = {
          ...API_CONFIG.POST_USER_REGISTER,
         
        };
        apiPayload.data =userData;

        
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );

  const userRegistrationSlice = createSlice({
    name: "post/user-register",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(userRegistrationApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(userRegistrationApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(userRegistrationApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const userRegistrationAction = userRegistrationSlice.actions;

//Api for login
export const userLoginApiCall = createAsyncThunk(
    "post/user-login",
    async ({userData}) => {

      try {
        const apiPayload = {
          ...API_CONFIG.POST_USER_LOGIN,
         
        };
        apiPayload.data =userData;

        
        const response = await apiClient(apiPayload);
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );

  const userLoginSlice = createSlice({
    name: "post/user-login",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(userLoginApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(userLoginApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(userLoginApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const userLoginAction = userLoginSlice.actions;


  const userLoginReducer={
    userRegisterData:userRegistrationSlice.reducer,
    userLoginData:userLoginSlice.reducer,
  }
  


  export default userLoginReducer