export const responseData = {
      "files": {
          "m2": [
              {
                  "number": 22,
                  "folder_name": "22-intro",
                  "children": [
                      {
                          "name": "subobject1",
                          "value": []
                      },
                      {
                          "name": "subobject2",
                          "value": []
                      }
                  ]
              },
              {
                  "number": 23,
                  "folder_name": "23-qos",
                  "children": [
                      {
                          "open": 1,
                          "name": "drug-substance.pdf",
                          "section": "2.3.S",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.S",
                                  "class": "section-2",
                                  "value": "2.3.S-QUALITY OVERALL SUMMARY (QOS)",
                                  "name": "drug-substance.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.S.1",
                                          "class": "section-4",
                                          "value": "2.3.S.1-GENERAL INFORMATION",
                                          "name": "drug-substance.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.S.1.1",
                                                  "class": "section-4",
                                                  "value": "Nomenclature",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.1.2",
                                                  "class": "section-4",
                                                  "value": "Structure",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.1.3",
                                                  "class": "section-4",
                                                  "value": "General Properties",
                                                  "name": "drug-substance.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.S.2",
                                          "class": "section-4",
                                          "value": "2.3.S.2-MANUFACTURE",
                                          "name": "drug-substance.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.S.2.1",
                                                  "class": "section-4",
                                                  "value": "Manufacturer(s)",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.2.2",
                                                  "class": "section-4",
                                                  "value": "Description Of Manufacturing Process And Process Controls",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.2.3",
                                                  "class": "section-4",
                                                  "value": "Control of materials",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.2.4",
                                                  "class": "section-4",
                                                  "value": "Control of Critical Steps and Intermediates",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.2.5",
                                                  "class": "section-4",
                                                  "value": "Process Validation",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.2.6",
                                                  "class": "section-4",
                                                  "value": "Manufacturing Process Development",
                                                  "name": "drug-substance.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.S.3",
                                          "class": "section-4",
                                          "value": "2.3.S.3-CHARACTERIZATION",
                                          "name": "drug-substance.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.S.3.1",
                                                  "class": "section-4",
                                                  "value": "Elucidation of Structure And Other Characteristics",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.3.2",
                                                  "class": "section-4",
                                                  "value": "Impurities",
                                                  "name": "drug-substance.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.S.4",
                                          "class": "section-4",
                                          "value": "2.3.S.4-CONTROL OF DRUG SUBSTANCE",
                                          "name": "drug-substance.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.S.4.1",
                                                  "class": "section-4",
                                                  "value": "Specification",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.4.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.4.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.4.4",
                                                  "class": "section-4",
                                                  "value": "Batch analyses",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.4.5",
                                                  "class": "section-4",
                                                  "value": "Justification Of Specification",
                                                  "name": "drug-substance.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.S.5",
                                          "class": "section-4",
                                          "value": "2.3.S.5-Reference Standards Or Materials",
                                          "name": "drug-substance.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.S.6",
                                          "class": "section-4",
                                          "value": "2.3.S.6-Container closure system",
                                          "name": "drug-substance.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.S.7",
                                          "class": "section-4",
                                          "value": "2.3.S.7-STABILITY",
                                          "name": "drug-substance.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.S.7.1",
                                                  "class": "section-4",
                                                  "value": "Stability Summary And Conclusion",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.7.2",
                                                  "class": "section-4",
                                                  "value": "Post Approval Stability Protocol And Stability Commitment",
                                                  "name": "drug-substance.pdf"
                                              },
                                              {
                                                  "id": "2.3.S.7.3",
                                                  "class": "section-4",
                                                  "value": "Stability Data",
                                                  "name": "drug-substance.pdf"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "open": 2,
                          "name": "appendices.pdf",
                          "section": "2.3.A",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.A",
                                  "class": "section-2",
                                  "value": "2.3.A-APPENDICES",
                                  "name": "appendices.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.A.1",
                                          "class": "section-4",
                                          "value": "2.3.A.1-FACILITIES AND EQUIPMENT",
                                          "name": "appendices.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.A.1",
                                                  "class": "section-4",
                                                  "value": "Facilities and Equipment",
                                                  "name": "appendices.pdf"
                                              },
                                              {
                                                  "id": "2.3.A.1.1",
                                                  "class": "section-4",
                                                  "value": "Description of Drug Substance Facility",
                                                  "name": "appendices.pdf"
                                              },
                                              {
                                                  "id": "2.3.A.1.2",
                                                  "class": "section-4",
                                                  "value": "Description of Drug Product Facility",
                                                  "name": "appendices.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.A.2",
                                          "class": "section-4",
                                          "value": "2.3.A.2-ADVENTITIOUS AGENTS SAFETY EVALUATION",
                                          "name": "appendices.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.A.3",
                                          "class": "section-4",
                                          "value": "2.3.A.3-EXCIPIENTS",
                                          "name": "appendices.pdf",
                                          "children": []
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "open": 3,
                          "name": "regional-information.pdf",
                          "section": "2.3.R",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.R",
                                  "class": "section-2",
                                  "value": "2.3.R-REGIONAL INFORMATION",
                                  "name": "regional-information.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.R.1",
                                          "class": "section-4",
                                          "value": "2.3.R.1-Comparative Analytical Assessment",
                                          "name": "regional-information.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.R.1",
                                                  "class": "section-4",
                                                  "value": "Comparative Analytical Assessment",
                                                  "name": "regional-information.pdf"
                                              },
                                              {
                                                  "id": "2.3.R.1.1",
                                                  "class": "section-4",
                                                  "value": "Approach to Analytical Similarity",
                                                  "name": "regional-information.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.R.2",
                                          "class": "section-4",
                                          "value": "2.3.R.2-Batch Record",
                                          "name": "regional-information.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.R.3",
                                          "class": "section-4",
                                          "value": "2.3.R.3-Post Approval Life Cycle Management Plan",
                                          "name": "regional-information.pdf",
                                          "children": []
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "open": 4,
                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                          "section": "2.3.P",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.P",
                                  "class": "section-2",
                                  "value": "2.3.P-DRUG PRODUCT",
                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.P.1",
                                          "class": "section-4",
                                          "value": "2.3.P.1-DESCRIPTION AND COMPOSITION",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.2",
                                          "class": "section-4",
                                          "value": "2.3.P.2-PHARMACEUTICAL DEVELOPMENT",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.2.1",
                                                  "class": "section-4",
                                                  "value": "Components of Drug Product",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.2",
                                                  "class": "section-4",
                                                  "value": "Drug Product",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.3",
                                                  "class": "section-4",
                                                  "value": "Manufacturing Process Development",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.4",
                                                  "class": "section-4",
                                                  "value": "Container Closure System",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.5",
                                                  "class": "section-4",
                                                  "value": "Microbiological Attributes",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.6",
                                                  "class": "section-4",
                                                  "value": "Compatibility",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.3",
                                          "class": "section-4",
                                          "value": "2.3.P.3-MANUFACTURE",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.3.1",
                                                  "class": "section-4",
                                                  "value": "Manufacturer(s)",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.2",
                                                  "class": "section-4",
                                                  "value": "Batch Formula",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.3",
                                                  "class": "section-4",
                                                  "value": "Description of Manufacturing Process and Process controls",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.4",
                                                  "class": "section-4",
                                                  "value": "Control of Critical Steps and Intermediates",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.5",
                                                  "class": "section-4",
                                                  "value": "Process Validation and/or Evaluation",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.4",
                                          "class": "section-4",
                                          "value": "2.3.P.4-Control of Excipients",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.4.1",
                                                  "class": "section-4",
                                                  "value": "Specification",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.4",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.5",
                                                  "class": "section-4",
                                                  "value": "Excipients of Animal or Human Origin",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.6",
                                                  "class": "section-4",
                                                  "value": "Novel Excipients",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.5",
                                          "class": "section-4",
                                          "value": "2.3.P.5-Control of Drug Product",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.5.1",
                                                  "class": "section-4",
                                                  "value": "Specification(s)",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.4",
                                                  "class": "section-4",
                                                  "value": "Batch Analyses",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.5",
                                                  "class": "section-4",
                                                  "value": "Characterization of Impurities",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.6",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification(s)",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.6",
                                          "class": "section-4",
                                          "value": "2.3.P.6-REFERENCE STANDARDS OR MATERIALS",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.7",
                                          "class": "section-4",
                                          "value": "2.3.P.7-Container Closure System",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.7.1",
                                                  "class": "section-4",
                                                  "value": "Specifications and Test Procedure",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.8",
                                          "class": "section-4",
                                          "value": "2.3.P.8-Stability",
                                          "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.8.1",
                                                  "class": "section-4",
                                                  "value": "Stability summary and conclusion",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.2",
                                                  "class": "section-4",
                                                  "value": "Post-Approval Stability Protocol and Stability Commitment",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.3",
                                                  "class": "section-4",
                                                  "value": "Stability Data",
                                                  "name": "drug-product-Bmab1200-45mg-SC-biocon.pdf"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "open": 5,
                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                          "section": "2.3.P",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.P",
                                  "class": "section-2",
                                  "value": "2.3.P-DRUG PRODUCT",
                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.P.1",
                                          "class": "section-4",
                                          "value": "2.3.P.1-DESCRIPTION AND COMPOSITION",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.2",
                                          "class": "section-4",
                                          "value": "2.3.P.2-PHARMACEUTICAL DEVELOPMENT",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.2.1",
                                                  "class": "section-4",
                                                  "value": "Components of Drug Product",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.2",
                                                  "class": "section-4",
                                                  "value": "Drug Product",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.3",
                                                  "class": "section-4",
                                                  "value": "Manufacturing Process Development",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.4",
                                                  "class": "section-4",
                                                  "value": "Container Closure System",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.5",
                                                  "class": "section-4",
                                                  "value": "Microbiological Attributes",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.6",
                                                  "class": "section-4",
                                                  "value": "Compatibility",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.3",
                                          "class": "section-4",
                                          "value": "2.3.P.3-MANUFACTURE",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.3.1",
                                                  "class": "section-4",
                                                  "value": "Manufacturer(s)",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.2",
                                                  "class": "section-4",
                                                  "value": "Batch Formula",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.3",
                                                  "class": "section-4",
                                                  "value": "Description of Manufacturing Process and Process controls",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.4",
                                                  "class": "section-4",
                                                  "value": "Control of Critical Steps and Intermediates",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.5",
                                                  "class": "section-4",
                                                  "value": "Process Validation and/or Evaluation",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.4",
                                          "class": "section-4",
                                          "value": "2.3.P.4-Control of Excipients",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.4.1",
                                                  "class": "section-4",
                                                  "value": "Specification",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.4",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.5",
                                                  "class": "section-4",
                                                  "value": "Excipients of Animal or Human Origin",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.6",
                                                  "class": "section-4",
                                                  "value": "Novel Excipients",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.5",
                                          "class": "section-4",
                                          "value": "2.3.P.5-Control of Drug Product",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.5.1",
                                                  "class": "section-4",
                                                  "value": "Specification(s)",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.4",
                                                  "class": "section-4",
                                                  "value": "Batch Analyses",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.5",
                                                  "class": "section-4",
                                                  "value": "Characterization of Impurities",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.6",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification(s)",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.6",
                                          "class": "section-4",
                                          "value": "2.3.P.6-REFERENCE STANDARDS OR MATERIALS",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.7",
                                          "class": "section-4",
                                          "value": "2.3.P.7-Container Closure System",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.7.1",
                                                  "class": "section-4",
                                                  "value": "Specifications and Test Procedure",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.8",
                                          "class": "section-4",
                                          "value": "2.3.P.8-Stability",
                                          "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.8.1",
                                                  "class": "section-4",
                                                  "value": "Stability summary and conclusion",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.2",
                                                  "class": "section-4",
                                                  "value": "Post-Approval Stability Protocol and Stability Commitment",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.3",
                                                  "class": "section-4",
                                                  "value": "Stability Data",
                                                  "name": "drug-product-Bmab1200-45mg and 90ml-PFS-Biocon.pdf"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "open": 6,
                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                          "section": "2.3.P",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.3.P",
                                  "class": "section-2",
                                  "value": "2.3.P-DRUG PRODUCT",
                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                  "prompt": "Provide an overview of the quality aspects of the drug product and drug substance.",
                                  "content": "This section provides a summary of the quality aspects of the drug product and drug substance, including manufacturing details, control measures, and stability.",
                                  "children": [
                                      {
                                          "id": "2.3.P.1",
                                          "class": "section-4",
                                          "value": "2.3.P.1-DESCRIPTION AND COMPOSITION",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.2",
                                          "class": "section-4",
                                          "value": "2.3.P.2-PHARMACEUTICAL DEVELOPMENT",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.2.1",
                                                  "class": "section-4",
                                                  "value": "Components of Drug Product",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.2",
                                                  "class": "section-4",
                                                  "value": "Drug Product",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.3",
                                                  "class": "section-4",
                                                  "value": "Manufacturing Process Development",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.4",
                                                  "class": "section-4",
                                                  "value": "Container Closure System",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.5",
                                                  "class": "section-4",
                                                  "value": "Microbiological Attributes",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.2.6",
                                                  "class": "section-4",
                                                  "value": "Compatibility",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.3",
                                          "class": "section-4",
                                          "value": "2.3.P.3-MANUFACTURE",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.3.1",
                                                  "class": "section-4",
                                                  "value": "Manufacturer(s)",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.2",
                                                  "class": "section-4",
                                                  "value": "Batch Formula",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.3",
                                                  "class": "section-4",
                                                  "value": "Description of Manufacturing Process and Process controls",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.4",
                                                  "class": "section-4",
                                                  "value": "Control of Critical Steps and Intermediates",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.3.5",
                                                  "class": "section-4",
                                                  "value": "Process Validation and/or Evaluation",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.4",
                                          "class": "section-4",
                                          "value": "2.3.P.4-Control of Excipients",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.4.1",
                                                  "class": "section-4",
                                                  "value": "Specification",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.4",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.5",
                                                  "class": "section-4",
                                                  "value": "Excipients of Animal or Human Origin",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.4.6",
                                                  "class": "section-4",
                                                  "value": "Novel Excipients",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.5",
                                          "class": "section-4",
                                          "value": "2.3.P.5-Control of Drug Product",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.5.1",
                                                  "class": "section-4",
                                                  "value": "Specification(s)",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.2",
                                                  "class": "section-4",
                                                  "value": "Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.3",
                                                  "class": "section-4",
                                                  "value": "Validation of Analytical Procedures",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.4",
                                                  "class": "section-4",
                                                  "value": "Batch Analyses",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.5",
                                                  "class": "section-4",
                                                  "value": "Characterization of Impurities",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.5.6",
                                                  "class": "section-4",
                                                  "value": "Justification of Specification(s)",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.6",
                                          "class": "section-4",
                                          "value": "2.3.P.6-REFERENCE STANDARDS OR MATERIALS",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": []
                                      },
                                      {
                                          "id": "2.3.P.7",
                                          "class": "section-4",
                                          "value": "2.3.P.7-Container Closure System",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.7.1",
                                                  "class": "section-4",
                                                  "value": "Specifications and Test Procedure",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      },
                                      {
                                          "id": "2.3.P.8",
                                          "class": "section-4",
                                          "value": "2.3.P.8-Stability",
                                          "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf",
                                          "children": [
                                              {
                                                  "id": "2.3.P.8.1",
                                                  "class": "section-4",
                                                  "value": "Stability summary and conclusion",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.2",
                                                  "class": "section-4",
                                                  "value": "Post-Approval Stability Protocol and Stability Commitment",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              },
                                              {
                                                  "id": "2.3.P.8.3",
                                                  "class": "section-4",
                                                  "value": "Stability Data",
                                                  "name": "drug-product-Bmab1200-130mg-IV-biocon.pdf"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "number": 24,
                  "folder_name": "24-nonclin-over",
                  "children": [
                      {
                          "name": "subobject3",
                          "children": []
                      },
                      {
                          "name": "subobject4",
                          "children": []
                      }
                  ]
              },
              {
                  "number": 25,
                  "folder_name": "25-clin-over",
                  "children": [
                      {
                          "name": "subobject3",
                          "children": []
                      },
                      {
                          "name": "subobject4",
                          "children": []
                      }
                  ]
              },
              {
                  "number": 26,
                  "folder_name": "26-nonclin-sum",
                  "children": [
                      {
                          "open": 1,
                          "name": "introduction.pdf",
                          "section": "2.6.1",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.1",
                                  "class": "section-2",
                                  "value": "2.6.1-Introduction",
                                  "name": "introduction.pdf",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "open": 2,
                          "name": "pharmacol-written-summary.pdf",
                          "section": "2.6.2",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.2",
                                  "class": "section-2",
                                  "value": "2.6.2-Pharmacology Written Summary",
                                  "name": "pharmacol-written-summary.pdf",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "open": 3,
                          "name": "pharmkin-written-summary.pdf",
                          "section": "2.6.4",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.4",
                                  "class": "section-2",
                                  "value": "2.6.4-Pharmacokinetics Written Summary",
                                  "name": "pharmkin-written-summary.pdf",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "open": 4,
                          "name": "pharmkin-tabulated-summary.pdf",
                          "section": "2.6.5",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.5",
                                  "class": "section-2",
                                  "value": "2.6.5-Pharmacokinetics Tabulated Summary",
                                  "name": "pharmkin-tabulated-summary.pdf",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "open": 5,
                          "name": "toxicology-written-summary.pdf",
                          "section": "2.6.6",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.6",
                                  "class": "section-2",
                                  "value": "2.6.6-Toxicology Written Summary",
                                  "name": "toxicology-written-summary.pdf",
                                  "children": []
                              }
                          ]
                      },
                      {
                          "open": 6,
                          "name": "toxicology-tabulated-summary.pdf",
                          "section": "2.6.7",
                          "type": "file",
                          "responseValue": [
                              {
                                  "id": "2.6.7",
                                  "class": "section-2",
                                  "value": "2.6.7-Toxicology Tabulated Summary",
                                  "name": "toxicology-tabulated-summary.pdf",
                                  "children": []
                              }
                          ]
                      }
                  ]
              },
              {
                  "number": 27,
                  "folder_name": "27-clin-sum",
                  "children": [
                      {
                          "name": "subobject3",
                          "children": []
                      },
                      {
                          "name": "subobject4",
                          "children": []
                      }
                  ]
              }
          ]
      }
 
};