// export const folderStructure={
//     "M1": {
//         "Labelling (Seq 0010)": {},
//         "Labelling (Seq 0013)": {
//             "m1-14-3-1-annotated-comparison-with-listed-drug": {},
//             "m1-14-3-3-labeling-text-for-reference-listed-drug": {}
//         }
//     },
//     "M2": {
//         "22-intro": {},
//         "23-qos": {
//             "2.3.A": {},
//             "2.3.P": {
//                 "Archive": {}
//             },
//             "2.3.R": {},
//             "2.3.S": {}
//         },
//         "24-nonclin-over": {},
//         "25-clin-over": {},
//         "26-nonclin-sum": {},
//         "27-clin-sum": {
//             "2.7.3_Summary of Efficacy": {},
//             "2.7.5_Literature references": {},
//             "2.7.6_Synopsis": {}
//         }
//     },
//     "M3": {
//         "32-body-data": {
//             "32a-app": {
//                 "32a1-fac-equip": {},
//                 "32a2-advent-agent": {},
//                 "32a3-excip-name-1": {}
//             },
//             "32p-drug-prod": {
//                 "Bmab1200-130mg-IV-biocon": {
//                     "32p1-desc-comp": {},
//                     "32p2-pharm-dev": {},
//                     "32p3-manuf": {},
//                     "32p4-contr-excip": {},
//                     "32p5-contr-drug-prod": {
//                         "32p5-charac-imp": {},
//                         "32p51-spec": {},
//                         "32p52-analyt-proc": {
//                             "archive": {}
//                         },
//                         "32p53-val-analyt-proc": {
//                             "Archival": {}
//                         },
//                         "32p54-batch-analys": {},
//                         "32p6-justif-spec": {}
//                     },
//                     "32p6-ref-stand": {},
//                     "32p7-cont-closure-sys": {},
//                     "32p8-stab": {}
//                 },
//                 "Bmab1200-45mg and 90ml-PFS-Biocon": {
//                     "32p1-desc-comp": {},
//                     "32p2-pharm-dev": {},
//                     "32p3-manuf": {},
//                     "32p4-contr-excip": {},
//                     "32p5-contr-drug-prod": {
//                         "32p5-charac-imp": {},
//                         "32p51-spec": {},
//                         "32p52-analyt-proc": {
//                             "archive": {}
//                         },
//                         "32p53-val-analyt-proc": {
//                             "Archival": {}
//                         },
//                         "32p54-batch-analys": {},
//                         "32p6-justif-spec": {}
//                     },
//                     "32p6-ref-stand": {},
//                     "32p7-cont-closure-sys": {},
//                     "32p8-stab": {}
//                 },
//                 "Bmab1200-45mg-SC-biocon": {
//                     "32p1-desc-comp": {},
//                     "32p2-pharm-dev": {},
//                     "32p3-manuf": {},
//                     "32p4-contr-excip": {},
//                     "32p5-contr-drug-prod": {
//                         "32p5-charac-imp": {},
//                         "32p51-spec": {},
//                         "32p52-analyt-proc": {
//                             "archive": {}
//                         },
//                         "32p53-val-analyt-proc": {
//                             "Archival": {}
//                         },
//                         "32p54-batch-analys": {},
//                         "32p6-justif-spec": {}
//                     },
//                     "32p6-ref-stand": {},
//                     "32p7-cont-closure-sys": {},
//                     "32p8-stab": {}
//                 }
//             },
//             "32r-reg-info": {},
//             "32s-drug-sub": {
//                 "Bmab-1200-Biocon": {
//                     "32s1-gen-info": {},
//                     "32s2-manuf": {},
//                     "32s3-charac": {},
//                     "32s4-contr-drug-sub": {
//                         "32s41-spec": {},
//                         "32s42-analyt-proc": {},
//                         "32s43-val-analyt-proc": {},
//                         "32s44-batch-analys": {},
//                         "32s45-justif-spec": {}
//                     },
//                     "32s5-ref-stand": {},
//                     "32s6-cont-closure-sys": {},
//                     "32s7-stab": {}
//                 }
//             }
//         }
//     },
//     "M4": {
//         "4.3": {
//             "4.3": {},
//             "Attachments (Literature reference 2.4 and 2.6.2 module2)": {},
//             "BDL MDR LR.19.0079 23 001 V01-Lack of ADCC and CDC": {},
//             "BDL MDR LR.19.0079 23 002 V01_Lack of binding to IL6 and IL10": {},
//             "BDL MDR LR.19.0079 23 008 V01-IFNg and IL17 release": {},
//             "Literature references for all protocols": {}
//         }
//     },
//     "M5": {}
// }



export const folderStructure={
    "M3": [
        // 32a-app
        /^m3\/32-body-data\/32a-app\/32a1-fac-equip(\/.*)?$/i,
        /^m3\/32-body-data\/32a-app\/32a2-advent-agent(\/.*)?$/i,
        /^m3\/32-body-data\/32a-app\/32a3-excip-name-1(\/.*)?$/i,
        // 32p-drug-prod
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p1-desc-comp(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p2-pharm-dev(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p3-manuf(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p4-contr-excip\/[^\/]+(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p51-spec(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p52-analyt-proc(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p53-val-analyt-proc(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p54-batch-analys(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p55-charac-imp(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p5-contr-drug-prod\/32p56-justif-spec(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p6-ref-stand(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p7-cont-closure-sys(\/.*)?$/i,
        /^m3\/32-body-data\/32p-drug-prod\/[^\/]+\/32p8-stab(\/.*)?$/i,
        // 32r-reg-info
        /^m3\/32-body-data\/32r-reg-info(\/.*)?$/i,
        // 32s-drug-sub
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s1-gen-info(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s2-manuf(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s3-charac(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s4-contr-drug-sub\/32s41-spec(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s4-contr-drug-sub\/32s42-analyt-proc(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s4-contr-drug-sub\/32s43-val-analyt-proc(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s4-contr-drug-sub\/32s44-batch-analys(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s4-contr-drug-sub\/32s45-justif-spec(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s5-ref-stand(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s6-cont-closure-sys(\/.*)?$/i,
        /^m3\/32-body-data\/32s-drug-sub\/[^\/]+\/32s7-stab(\/.*)?$/i,
        // 33-lit-ref
        /^m3\/33-lit-ref(\/.*)?$/i,
    ],
}