import React, { useEffect, useState } from "react";
import "./loginPage.scss";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction, userLoginApiCall, userRegistrationAction, userRegistrationApiCall } from "./logic";
import { setHeader } from "../../utils/apiClient";
import { addSession } from "../../utils/sessionManagement";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getData } from "../../utils/storageService";

const LoginPage = () => {
  const [showLogo, setShowLogo] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userRegisterData } = useSelector((state) => state?.userRegisterData);
  const { data: userLoginData } = useSelector((state) => state?.userLoginData);

  useEffect(() => {
    // Handle user registration
    if (userRegisterData) {
      if (userRegisterData.statusCode === 200) {
        toast.success(userRegisterData.response?.data?.message || 'Registration successful');
        setShowSignUp(false);
      } else {
        toast.error(userRegisterData.response?.data?.message || 'Registration failed');
      }
    }

    // Handle user login
    if (userLoginData) {
      if (userLoginData.statusCode === 200) {
        toast.success(userLoginData?.message || 'Login successful');
        setHeader(userLoginData.token);
        addSession(userLoginData);
        navigate('/home');
      } else {
        toast.error(userLoginData?.message || 'Login failed');
      }
    }

    setTimeout(()=>{
      dispatch(userRegistrationAction.resetCount());
      dispatch(userLoginAction.resetCount())
    },[3000])
  }, [userRegisterData, userLoginData, navigate]);

  useEffect(() => {
    const token = getData("Token");

    setTimeout(() => {
      setShowLogo(false);
      !!token && navigate("/home") ;
      

    }, 2000);
  }, []);

  const onRegisterFinish = (values) => {
    dispatch(userRegistrationApiCall({ userData: values }));
  };

  const onLoginFinish = (values) => {
    dispatch(userLoginApiCall({ userData: values }));
  };

  return (
    <div className="login-page" style={{ backgroundColor: "#F7FAFF" }}>
      {showLogo ? (
        <div className="welcome-logo">
          <img src="/images/ai-ectd-logo.svg" alt="Logo" />
          AI eCTD generator
        </div>
      ) : (
        <>
          {!showSignUp ? (
            <div className="login-box">
              <div className="content">
                <div className="welcome-text">Welcome Back 👋</div>
                <div className="welcome-quote">
                  Today is a new day. It's your day. You shape it. Sign in to start managing your projects.
                </div>
                <Form
                  name="login"
                  initialValues={{ remember: true }}
                  onFinish={onLoginFinish}
                  className="custom-form"
                >
                  <label>Username</label>
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: "Please input your Username!" }]}
                  >
                    <Input placeholder="Username" className="input-box" />
                  </Form.Item>
                  <label>Password</label>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                  >
                    <Input type="password" placeholder="At least 8 characters" className="input-box" />
                  </Form.Item>
                  {/* <Form.Item>
                    <div className="forgot-password" style={{pointerEvents:"none"}}>
                      <a href="/" style={{ color: "#1849D6" }}>Forgot password?</a>
                    </div>
                  </Form.Item> */}
                  <Form.Item>
                    <Button block type="primary" htmlType="submit" className="submit-button">
                      Sign in
                    </Button>
                    <div className="welcome-signup">
                      Don't have an account?{" "}
                      <span onClick={() => setShowSignUp(true)}>Sign Up</span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : (
            <div className="login-box">
              <div className="content">
                <div className="welcome-text">Create an account</div>
                <Form
                  name="register"
                  initialValues={{ remember: true }}
                  onFinish={onRegisterFinish}
                  className="custom-form"
                >
                  <label>Enter your name</label>
                  <Form.Item
                    name="full_name"
                    rules={[{ required: true, message: "Please input your name!" }]}
                  >
                    <Input placeholder="Suraj..." className="input-box" />
                  </Form.Item>
                  <label>Enter your username</label>
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: "Please input your Username!" }]}
                  >
                    <Input placeholder="username123" className="input-box" />
                  </Form.Item>
                  <label>Enter your email address</label>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your Email!" }]}
                  >
                    <Input placeholder="Example@email.com" className="input-box" type="email" />
                  </Form.Item>
                  <label>Enter your password</label>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                  >
                    <Input type="password" placeholder="At least 8 characters" className="input-box" />
                  </Form.Item>
                  <Form.Item>
                    <Button block type="primary" htmlType="submit" className="submit-button">
                      Create an account
                    </Button>
                    <div className="welcome-signup">
                      Already have an account?{" "}
                      <span onClick={() => setShowSignUp(false)}>Sign in</span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LoginPage;
