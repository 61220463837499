import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../utils/apiClient";
import { toast } from "react-toastify";


const API_CONFIG = {
 
 
    GET_FOLDER_SECTIONS:{
      method: "GET",
      data: {},
      url: "/v0/module/getSections",
    },
  
   
  };
  
  const InitialState = {
    loading: false,
    isError: false,
    data: null,
    error: {},
  };


// Api call for folder structure
export const getFoldersSectionsApiCall = createAsyncThunk(
    "get/get-folders-sections",
    async ({identifier_name}) => {
      try {
        const apiPayload = {
          ...API_CONFIG.GET_FOLDER_SECTIONS,
          params:{
            identifier_name,
          }
        
        };
        const response = await apiClient(apiPayload);
        return response?.data?.data;
      } catch (error) {
        localStorage.clear();
        toast.error("Session Expired")
        setTimeout(()=>{
    window.location.reload();
        },[500])
        return error;
      }
    }
  );
  
  const getFoldersSectionsSlice = createSlice({
    name: "get/get-folders-sections",
    initialState: InitialState,
    reducers: {
      resetCount: (state) => {
        state.data = null;
        state.error = null;
        state.isError = false;
        state.loading = true;
      },
    },
    extraReducers(builder) {
      builder
        .addCase(getFoldersSectionsApiCall.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
          state.isError = false;
          state.flag = false;
        })
        .addCase(getFoldersSectionsApiCall.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.error = null;
          state.isError = false;
          state.flag = true;
        })
        .addCase(getFoldersSectionsApiCall.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload;
          state.isError = true;
          state.flag = false;
        });
    },
  });
  
  export const getFoldersSectionsAction = getFoldersSectionsSlice.actions;





const foldersSectionsReducer={
    getFolderStructureData:getFoldersSectionsSlice.reducer
}
export default foldersSectionsReducer;



