import React, { useState, useRef, useEffect } from "react";
import "./searchFolder.scss";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Menu, Button, Modal, Input, Checkbox, message, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { demoData } from "./demoData";
import PDFViewer from "../../component/PDFViewer";
import { useDispatch, useSelector } from "react-redux";
import { generateAllApiCall, getModuleFilesApiCall, uploadModuleFilesApiCall } from "./logic";
import { chunk } from "@react-pdf-viewer/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert, Flex, Spin } from 'antd';
import { folderStructure } from "./folder_structure";
import { getModulesSectionsAction } from "../NewSummarization/logic";
const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;
const SearchFolders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const CheckboxGroup = Checkbox.Group;
  
const plainOptions = [1,2,3];
const defaultCheckedList = [];
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [openFile, setOpenFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); // State to store the selected folder or file for upload
  const [selectedModule, setSelectedModule] = useState(null); // State to store the selected module for upload
  const fileInputRef = useRef(null);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  const urlSearchParams = new URLSearchParams(location.search);
  const searchText = urlSearchParams.get("search");
  const statusOfDrug=urlSearchParams.get("status");
 let closeModal=true;
  const { data: moduleFilesData, loading: moduleFilesDataLoading } = useSelector((state) => state?.getModuleFilesData);
  const { data: uploadFileData, loading: uploadFileDataLoading } = useSelector((state) => state?.uploadFileData);

  const { data: generateAllData } = useSelector((state) => state?.generateAllData)
  // Initialize open keys state to ensure all modules are open by default
  const [openKeys, setOpenKeys] = useState(['module-1','module-3', 'module-4', 'module-5']
    // moduleFilesData?.map((module) => `module-${module??.module}`)
  );
  const [uploadLoading, setUploadLoading] = useState(false);
  useEffect(() => {
    // Set attributes on fileInputRef when it's available
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute("webkitdirectory", "true");
      fileInputRef.current.setAttribute("directory", "true");
    }
  }, [fileInputRef]);


  useEffect(() => {
    dispatch(getModuleFilesApiCall({ searchText }));
  }, [])


  useEffect(() => {
    if (uploadFileData) {
      alert("upload successfully")
      window.location.reload();
    }
  }, [uploadFileData])



  const handleMenuClick = (e) => {
    setOpenKeys(e.keyPath);
  };

  const generateSummary = () => {
    dispatch(generateAllApiCall({ searchText ,selectedModules}));
    navigate(`/summary?drug_name=${searchText}`)
    dispatch(getModulesSectionsAction.resetCount());


  }


  const handleFileInputChange = async (e, module) => {
    const chunkSize = 10;
    const chunkList = [];
 
    const files = Array.from(e.target.files);
    let fileFlag = true
    for (const file of files) {
      if (!checkM3EctdFilePath(file.webkitRelativePath,module)) {
        toast.error("Folder structure is not correct");
        fileFlag = false;
        setTimeout(()=>{
          window.location.reload()
        },[1500])
        break; 
      }
      fileFlag = fileFlag && checkM3EctdFilePath(file.webkitRelativePath);
    }
 if(fileFlag){ 
    for (let i = 0; i < files.length; i += chunkSize) {
      chunkList.push(files.slice(i, i + chunkSize));
    }
    setUploadLoading(true);
    const apiCallPromises = chunkList.map(async (chunk) => {
      const formData = new FormData();
      chunk.forEach((file) => {
        formData.append("files", file, window.btoa(file.webkitRelativePath));
      });
 
      return dispatch(uploadModuleFilesApiCall({
        formData,
        identifier_name: urlSearchParams.get("search"),
        module_name: module?.module
      }));
    });
 
    try {
      // Wait for all API calls to complete
      await Promise.all(apiCallPromises);
      toast.success("Upload successfully")
      setUploadLoading(false);
      setTimeout(()=>{window.location.reload()},[3000])
    } catch (error) {
      setUploadLoading(false);
 
    }}
  };
 
function checkM3EctdFilePath(filePath,module) {

  if(module?.module === "3")
  {
    return folderStructure?.M3?.some(pattern => pattern.test(filePath));
  }
  else{
    return true;
  }
  
  // return folderStructure?.M3?.some(pattern => pattern.test(filePath));
}
  const [selectedModules, setSelectedModules] = useState({});
  const [selectAllModules, setSelectAllModules] = useState(false);
  let selectedKeys = [];
  
  const logSelectedCheckboxes = () => {
    // Compute selected keys
    selectedKeys = Object.keys(selectedModules).filter(key => selectedModules[key]);
  
  
  };
  
  
 
// Handle "Select All" checkbox change
const handleAllCheckboxChange = () => {
  const newSelectAllState = !isAllSelected; // Toggle based on current state
  
  // Set all selectable modules to the new state
  setSelectedModules((prev) => {
    const newState = { ...prev };
    moduleFilesData?.data.forEach(module => {
      if (module?.folders?.length > 0) { // Only affect enabled modules
        newState[module?.module] = newSelectAllState;
      }
    });
    return newState;
  });
};
  
  // Handle individual checkbox change
  const handleCheckboxChange = (moduleKey) => {
    setSelectedModules((prev) => {
      const newState = {
        ...prev,
        [moduleKey]: !prev[moduleKey], // Toggle the checked state
      };
      return newState;
    });
  
    // Log the selected checkboxes
    logSelectedCheckboxes();
  };
  
// Determine if all selectable (enabled) modules are selected
const isAllSelected = moduleFilesData?.data?.every(module => 
  module?.folders?.length === 0 || selectedModules[module?.module]
);

// Check if all modules are selected
// const isAllSelected = moduleFilesData?.every(module => selectedModules[module?.module]);
const isGenerateButtonEnabled = Object.keys(selectedModules).some(key => selectedModules[key]);
const renderModules = () => {

  return moduleFilesData?.data?.map((module) => (
    <Menu.SubMenu
      key={`module-${module?.module}`}
      title={
        <div className="module-upload">
          <div className="module-header">
            <input
              type="checkbox"
              checked={module?.folders?.length !== 0 && !!selectedModules[module?.module]}
              onChange={() => handleCheckboxChange(module?.module)}
              className="module-checkbox"
              disabled={module?.folders?.length === 0}
            />
            <span>Module {module?.module}</span>
          </div>
          <div style={{ marginRight: "20px" }}>
            <div>
              <span>
                <UploadOutlined
                  className="upload-icon"
                  onClick={(e) => e.stopPropagation()}
                />
                Upload
              </span>
            </div>
          </div>
          <input
            type="file"
            webkitdirectory="true"
            directory="true"
            multiple
            ref={fileInputRef}
            onChange={(e) => handleFileInputChange(e, module)}
            className="input-section"
            title="Upload a folder"
          />
        </div>
      }
      disabled
      className="module-title"
      expandIcon={false}
      style={{cursor:"default"}}
    >
      {module.folders?.map((folder) => renderFolder(folder, module))}
    </Menu.SubMenu>
  ));
};


const [openFolders, setOpenFolders] = useState({});

const handleFolderClick = (folderName) => {

  // Toggle the folder's open/closed state
  setOpenFolders((prevState) => ({
    ...prevState,
    [folderName]: !prevState[folderName],
  }));
};

const renderFolder = (folder, module) => {

  return (
    <Menu.SubMenu
      key={`folder-${folder.folder_name}`}
      title={
        <span className="folder-rows" onClick={() => handleFolderClick(folder.folder_name)}>
          <img
            src="/images/folder-icon.svg"
            alt="folder"
            className="folder-icon"
          />
          <div className="folder-options">
            {folder.folder_name}
            {/* <UploadOutlined
              className="upload-icon"
            />
            <input
              type="file"
              webkitdirectory="true"
              directory="true"
              multiple
              ref={fileInputRef}
              onChange={(e) => handleFileInputChange(e, module)}
              className="input-section"
            /> */}
          </div>
        </span>
      }
      expandIcon={true}

      className="submenu-title"
      style={{ fontWeight: "normal" }}
      open={openFolders[folder.folder_name]} // Control open state based on state
    >
      {folder.files?.map((file, fileIndex) =>
        typeof file.name === "string" ? (
          <Menu.Item
            key={`file-${fileIndex}`}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              className="file-rows"
            >
              <img
                src="/images/file-icon.svg"
                alt="file"
                className="file-icon"
              />
              <div className="file-options">
                {file.name}
                <span>
                <Tooltip title="View File">
                <EyeOutlined
                    className="view-icon"
                    onClick={() => {
                      setShowModalOpen(true);
                      setOpenFile(file);
                    }}
                  /></Tooltip> 
               
                  {/* <UploadOutlined
                    className="upload-icon"
                  /> */}
              
                </span>
              </div>
            </span>
          </Menu.Item>
        ) : (
          <Menu.SubMenu key={`file-${file.name}`} title={file.name}>
            {file.subfiles?.map((subfile, subfileIndex) => (
              <Menu.Item
                key={`subfile-${subfileIndex}`}
              >
                <div className="file-options">
                  {subfile}
                  <span>
                    <EyeOutlined
                      className="view-icon"
                      onClick={() => {
                        setShowModalOpen(true);
                        setOpenFile(subfile?.url);
                      }}
                    />
                    <UploadOutlined
                      className="upload-icon"
                    />
                  </span>
                </div>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )
      )}
      {folder.subfolders &&
        folder.subfolders.map((subfolder) => renderFolder(subfolder))}
    </Menu.SubMenu>
  );
};


  return (
    <>
      {(!moduleFilesDataLoading && !uploadLoading) ? (
        <>
          <Modal
            title={
              selectedItem
                ? selectedItem.folder_name
                : selectedModule
                  ? `Module ${selectedModule?.module}`
                  : ""
            }
            centered
            visible={showModalOpen}
            // onOk={() => setShowModalOpen(false)}
            onCancel={() => {setShowModalOpen(false);closeModal=false}}
            width={"100%"}
            className="pdf-modal"
            cancelText
          >
            <PDFViewer openFile={openFile} closeModal />
          </Modal>

          <div className="search-folders">
            <div className="search-folders-content">
              <div className="btn-back" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined className="arrow-icon" />
                Back
              </div>
              <div className="title-search"><div className="search-text">{searchText}</div> <div>

<input
  type="checkbox"
  checked={isAllSelected}
  onChange={handleAllCheckboxChange}
  className="module-checkbox"
/>
<label>Select All</label>
      </div></div>

              <Menu
                mode="inline"
                defaultOpenKeys={openKeys}
                onClick={handleMenuClick}
                className="menus"
              >
                {renderModules()}
              </Menu>

              <div className="btn-section">
              <Button
                  className="btn-solid-secondary"
                  onClick={()=>   {
                     navigate(`/summary?drug_name=${searchText}`);
                     dispatch(getModulesSectionsAction?.resetCount())
                     
                    }
                }
                  // onClick={generateSummary}
                  disabled={moduleFilesData?.module_status === "New"}
                >
                  
                  View Previous Work
                </Button>
                <Button
                  className="btn-solid-blue"
                  onClick={generateSummary}
                  disabled={!isGenerateButtonEnabled}

                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loader-section">
          <Flex gap="middle" vertical className="loader-section-vertical">
            <Flex gap="middle" className="loader-section-middle">

              <Spin tip="" size="large" className="loader">
                {content}
              </Spin>
              <div className="heading-2">Uploading ...</div>
            </Flex>

          </Flex>

        </div>
      )}
    </>
  );
};

export default SearchFolders;
