import React, { useEffect, useRef, useState, version } from "react";
import {
  ArrowLeftOutlined,
  CodeOutlined, OneToOneOutlined,
  EditOutlined,
  DownloadOutlined, FileOutlined, LockOutlined,ReloadOutlined
} from "@ant-design/icons";
import { Tooltip } from 'antd';

import "./summary.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { responseData } from "./summaryResponse";
import { usePDF } from "react-to-pdf";
import { Element as ScrollElement, scroller } from "react-scroll";
import { Button, Modal, Select } from "antd";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { getAllSectionsApiCall } from "../Summarization/logic";
import { editSectionApiCall, generateSectionApiCall, getModulesSectionProcessApiCall, getModulesSectionsApiCall, lockSectionsApiCall, selectVersion1Action, selectVersion1ApiCall, selectVersion2Action, selectVersion2ApiCall, selectVersionApiCall, selectVersionListApiCall } from "./logic";
import TextArea from "antd/es/input/TextArea";
import CustomLoader from "../../component/Loader";
import { StringDiff } from "react-string-diff";
import ProgressBar from "../../component/ProgressBar";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import PDFViewer from "../../component/PDFViewer";

const NewSummaryData = ({showLoader,setShowLoader, setHandleKey, handleKey,defaultOpenKey,setDefaultOpenKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const identifier_name = urlSearchParams.get("drug_name")
const [currentId,setCurrentId]=useState("");
const [showTooltip,setShowToolTip]=useState(true);
  const summarySectionRef = useRef(null);
  const [selectedDownloadOption, setSelectedDownloadOption] = useState("pdfDownload");
  const [editableSections, setEditableSections] = useState({});
  const [editedSections, setEditedSections] = useState({
    value: "",
    content: "",
    prompt: "",
    id: "",
  });
  const [resetSections, setResetSections] = useState({
    value: "",
    content: "",
    prompt: "",
    id: "",
  });



  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [openFile,setOpenFile]=useState();
  const [isRegenerateModalIsOpen, setIsRegenerateModalIsOpen] = useState(false);
  const [downloading, setDownloading] = useState(true);
  const [scrollHeight, setScrollHeight] = useState();
  const [selectVersion, setSelectVersion] = useState(null);
  const [selectCompareVersion1, setSelectCompareVersion1] = useState(null);
  const [selectCompareVersion2, setSelectCompareVersion2] = useState(null);
  const [currentFile,setCurrentFile]=useState(0);


  const [editPrompt, setEditPrompt] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [generate,setGenerate]=useState(false);
  const [currentProgress,setCurrentProgress]=useState(0)
  const [clonedSummarySection, setClonedSummarySection] = useState(null);
  const { data: sectionVersionList } = useSelector((state) => state?.sectionVersionList)
  const { data: sectionVersionsData } = useSelector((state) => state?.sectionVersionsData)
  const { data: section1CompareData } = useSelector((state) => state?.section1CompareData)
  const { data: section2CompareData } = useSelector((state) => state?.section2CompareData)
const {data:editSectionData}=useSelector((state)=>state?.editSectionData);
  const { data: getModuleSectionData, loading: getModuleSectionDataLoading } = useSelector((state) => state?.getModulesSectionsData)
  const {data:modulesProcessData}=useSelector((state)=>state?.modulesProcessData)



  useEffect(() => {
 

    setTimeout(() => {setDownloading(false); }, [20000])
    // dispatch(getAllSectionsApiCall({ searchText: identifier_name }))
    // dispatch(getModulesSectionsApiCall({ searchText: identifier_name ,file_name:"drug-substance.pdf"}))

//     setInterval(() => {
// if(getModuleSectionData?.progress_percent < 100 ){ 
//   console.log("inside new summarization")
//        dispatch(getModulesSectionsApiCall({ searchText: identifier_name ,file_name:"drug-substance.pdf"}))}    
//   setTimeout(() => {
//         const summarySectionElements = document.getElementsByClassName("summarydata-section");

//         if (summarySectionElements.length > 0) {
//           const summarySectionElement = summarySectionElements[0]; // Assuming there's only one element

//           // Clone the element excluding elements with class "edit-section"
//           const clonedElement = cloneWithoutClass(summarySectionElement, "edit-section");
//           const clonedElement2 = cloneWithoutClass(clonedElement, "prompt");

//           setClonedSummarySection(clonedElement2);
//         }
//       }, [5000])
//     }, [60000])
    // Get the element with className "summarydata-section" after component mounts
    setTimeout(() => {
      const summarySectionElements = document.getElementsByClassName("summarydata-section");

      if (summarySectionElements.length > 0) {
        const summarySectionElement = summarySectionElements[0]; // Assuming there's only one element

        // Clone the element excluding elements with class "edit-section"
        const clonedElement = cloneWithoutClass(summarySectionElement, "edit-section");
        const clonedElement2 = cloneWithoutClass(clonedElement, "prompt");

        setClonedSummarySection(clonedElement2);
      }
    }, [5000])
  }, []);

 useEffect(()=>{
  if(defaultOpenKey !== undefined){
    setCurrentId(defaultOpenKey)

  }
 },[defaultOpenKey])

  // useEffect(() => {
  //   // Check if the ref is set and the element exists
  //   if (summarySectionRef.current) {
  //     // Set the initial scroll position
  //     summarySectionRef.current.scrollTop = scrollHeight; // Set this to your desired height
  //   }
  // }, [getModuleSectionData?.data]);



  useEffect(() => {
    if (currentId !== "" ) {
      setTimeout(()=>{
        if (summarySectionRef.current) {

          document?.getElementById(currentId)?.scrollIntoView({behavior:"smooth"});
        }
      },[1500])
      

    }
    const summarySectionElements = document.getElementsByClassName("summarydata-section");

    if (summarySectionElements.length > 0) {
      const summarySectionElement = summarySectionElements[0]; // Assuming there's only one element

      // Clone the element excluding elements with class "edit-section"
      const clonedElement = cloneWithoutClass(summarySectionElement, "edit-section");
      const clonedElement2 = cloneWithoutClass(clonedElement, "prompt");

      setClonedSummarySection(clonedElement2);
    }
    setDownloading(true);
    setTimeout(() => {setDownloading(false); }, [20000])
   

   

  }, [getModuleSectionData?.data]);


  useEffect(()=>{
if(!getModuleSectionDataLoading)
{
  setShowLoader(true);
  setTimeout(()=>{
    setShowLoader(false)
  },[2000])
}
  },[getModuleSectionDataLoading])


  // change prompt on version selection
  useEffect(() => {
    if (sectionVersionsData?.section?.prompt !== null) {
      setEditedSections({
        ...editedSections,
        prompt: sectionVersionsData?.section?.prompt,
        content: sectionVersionsData?.section?.content_html,
      })
    }
  }, [sectionVersionsData?.section?.prompt])



  // select prompt version
  useEffect(() => {
    if (selectVersion !== null) {
      dispatch(selectVersionApiCall({ identifier_name, section_name: editedSections?.id, version: selectVersion,file_name: getModuleSectionData?.file_name}))

    }
  }, [selectVersion])


  // select compare version 1
  useEffect(() => {
    if (selectCompareVersion1 !== null) {

      dispatch(selectVersion1ApiCall({ identifier_name, section_name: editedSections?.id, version: selectCompareVersion1,file_name: getModuleSectionData?.file_name }))

    }
  }, [selectCompareVersion1])


  // select compare version 2
  useEffect(() => {
    if (selectCompareVersion2 !== null) {
      dispatch(selectVersion2ApiCall({ identifier_name, section_name: editedSections?.id, version: selectCompareVersion2,file_name: getModuleSectionData?.file_name }))

    }
  }, [selectCompareVersion2])



  // Api call for versions on click edit
  useEffect(() => {
    if (!!isEditModalOpen) {
      setTimeout(() => {
        dispatch(selectVersionListApiCall({ identifier_name, section_name: editedSections?.id ,file_name: getModuleSectionData?.file_name}))

      }, [500])
    }
  }, [!!isEditModalOpen])

  const RefreshResponse =()=>{
    setGenerate(false);
    if(getModuleSectionData?.file_name ){
      setShowLoader(true);
      dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));      
}
  }

  useEffect(()=>{
    if (modulesProcessData?.data) {
      setCurrentProgress(modulesProcessData?.progress_percent);

      if(getModuleSectionData?.progress_percent !== 100){
        setCurrentProgress(modulesProcessData?.progress_percent);

      if(modulesProcessData?.progress_percent>currentProgress)
      {
        // console.log("inside progress percent")
        setGenerate(true);
      }
      if(currentProgress < 100)
      {
        console.log("inside processapi call",currentProgress)

        const intervalId = setInterval(() => {
// if(getModuleSectionData?.file_name )
// {

  dispatch(getModulesSectionProcessApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));      

// }
      }, 3000);
      return () => clearInterval(intervalId); // Cleanup on unmount or when dependencies change

    }
    if(currentProgress === 100)
    {
      // toast.success("Summary Generated Successfully , Reloading the data")
      // console.log("Process completed");
      setGenerate(false);
RefreshResponse();
    }
      
    }
  }
    // console.log(currentProgress,"percent")

  },[ modulesProcessData?.file_name && modulesProcessData?.data && getModuleSectionData?.data])


  // Api call on version
  // Function to clone an element excluding certain classes
  const cloneWithoutClass = (element, classNameToExclude) => {
    const clonedElement = element.cloneNode(true);
    const elementsToExclude = clonedElement.getElementsByClassName(classNameToExclude);

    // Remove elements with specified class name from the cloned element
    while (elementsToExclude.length > 0) {
      elementsToExclude[0].parentNode.removeChild(elementsToExclude[0]);
    }

    return clonedElement;
  };
  // Function to log the cloned element for verification
  const versionOptions = sectionVersionList?.versions?.map((e) => {
    return {
      value: e,
      label: e
    }
  });
  const applyInlineStyles = (element) => {
    // Example: Apply inline styles directly to elements by class name
    const titleElements = element?.querySelectorAll('.title');
    titleElements?.forEach(title => {
      title.style.fontWeight = 'bold';
    });

    const heading2Elements = element?.querySelectorAll('.heading-2');
    heading2Elements?.forEach(heading2 => {
      heading2.style.fontWeight = 'bold';
      heading2.style.fontSize = '22px';
      heading2.style.margin = '20px 0';
    });

    const heading3Elements = element?.querySelectorAll('.heading-3');
    heading3Elements?.forEach(heading3 => {
      heading3.style.fontWeight = 'bold';
      heading3.style.fontSize = '18px';
      // heading3.style.marginTop='20px';
    });

    const promptElements = element?.querySelectorAll('.prompt');
    promptElements?.forEach(prompt => {
      prompt.style.fontSize = '18px';
      prompt.style.margin = '10px 0';

    });

    const contentElements = element?.querySelectorAll('.content');
    contentElements?.forEach(content => {
      content.style.fontSize = '18px';
      content.style.margin = '10px 0';

    });
    const imageElements = element?.querySelectorAll('img');
    imageElements?.forEach(img => {
      img.style.width = '100%';
      img.style.height = 'auto';
    });
  };
  const sendToAPIAndDownloadPDF = async () => {
    setDownloading(true)
    const clonedElement = clonedSummarySection?.cloneNode(true);

    await applyInlineStyles(clonedElement);

    setTimeout(() => {
      const dataToSend = {
        htmlText: clonedElement?.outerHTML,
        section:getModuleSectionData?.file_name,
        drug_name:identifier_name
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/v0/module/${selectedDownloadOption}`, {
        method: 'POST',
        headers: {
          
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          setDownloading(false)
          toast.success("File downloaded")

          const url = URL?.createObjectURL(blob);

          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          if(selectedDownloadOption === "pdfDownload")
          {
            a.download = getModuleSectionData?.file_name || 'summary_data.pdf';
          }
          else{
            let file =getModuleSectionData?.file_name
            let pos = file.lastIndexOf(".");
file = file.substr(0, pos < 0 ? file.length : pos) + ".docx";
            a.download= file || "summaryData.docx";

          }
          
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch(error => {
          setDownloading(false)
          toast.error("Failed to download a file")


        });
    }, 100);
  };

  const [elementData, setElementData] = useState();

  const updateContent = () => {
    setEditContent(false);
    setEditPrompt(false);
    const dataToSend = {
      content: elementData,
      prompt: editedSections?.prompt,
    };
    setIsEditModalOpen(false);
setShowToolTip(true); 
dispatch(editSectionApiCall({ identifier_name, section_name: editedSections?.id, dataToSend: dataToSend,file_name: getModuleSectionData?.file_name }))

  // setTimeout(()=>{
  //     dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));
  
  //   },[3000])
  };

  useEffect(()=>{
    if(editSectionData?.statusCode=== 200)
    {

  setTimeout(()=>{
      dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));
      setShowLoader(true);

    },[1000])

    }
  },[editSectionData?.statusCode])

  const updatePrompt = () => {
    const dataToSend = {
      prompt: editedSections?.prompt,
    };
    setEditContent(false);
    setEditPrompt(false);

    dispatch(generateSectionApiCall({ identifier_name, section_name: editedSections?.id, dataToSend: dataToSend ,file_name: getModuleSectionData?.file_name }))
    setIsEditModalOpen(false);
setShowToolTip(true);   
setShowLoader(true);

 dispatch(getModulesSectionsApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));
 dispatch(getModulesSectionProcessApiCall({ searchText: identifier_name, file_name: getModuleSectionData?.file_name }));


  };
  const [timeRemainig,setTimeRemaining]=useState();
  function getTimeUntilLocked(lockedUntilTimestamp) {
    const intervalId = setInterval(() => {
        const currentTimestamp = Date.now();
        
        const timeDifference = lockedUntilTimestamp - currentTimestamp;
        
        if (timeDifference <= 0) {
          setTimeRemaining("Expired")
            clearInterval(intervalId); 
            return;
        }

        const totalSeconds = Math.floor(timeDifference / 1000);
        
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        return  `${hours}h ${minutes}m ${seconds}s`
    }, 1000); 
}


const changeFile=(idx)=>{
  setCurrentFile(idx);
}
  useEffect(() => {
    if (handleKey) {
      scroller.scrollTo(handleKey, {
        duration: 500,
        smooth: true,
        containerId: "summarydata-section",
        offset: -50,
      });
    }
  }, [handleKey]);
  const resetState = () => {
    setEditContent(false);
    setEditPrompt(false);
    setEditableSections({});
    setSelectVersion(null);
    setSelectCompareVersion1(null);
    setSelectCompareVersion2(null);
    setShowCompare(false);
    dispatch(selectVersion1Action?.resetCount())
      dispatch(selectVersion2Action?.resetCount())
      

      setSelectCompareVersion1(null);
      setSelectCompareVersion2(null);
    setEditedSections({
      value: "",
      content: "",
      prompt: "",
      id: "",
    });
    setResetSections({
      value: "",
      content: "",
      prompt: "",
      id: "",
    });

    setTimeout(() => {
      setIsEditModalOpen(false);
setShowToolTip(true);    }, [100])




  };
  const renderSections = (data) => {
    return data?.map((item, index) => (
      <div className="rendersections" key={index}>
        <div className={item.class} id={item.id} >

          {item?.content ? <div className="content" dangerouslySetInnerHTML={{ __html: item?.content || "-" }}></div>
            : <div className="content"><CustomLoader className="loader-section" /></div>}
          <div className="horizontal-line"></div>
          {item.children && renderSections(item.children)}
        </div>

        {item?.content && item?.prompt !== " " && <div className="edit-section"
        >
          {(!item?.locked || timeRemainig === "Expired")  ?
            <Tooltip title="Edit" placement="right">

              <div >
                <img src="/images/edit-pen.svg"
                  alt=""
                  onClick={() => {
                    if (!item?.locked || timeRemainig === "Expired") {
                      setEditableSections({ item });
                      setEditedSections({
                        value: item?.value,
                        content: item?.content,
                        prompt: item?.prompt,
                        id: item?.id,
                      }); 
                      setResetSections({
                        value: item?.value,
                        content: item?.content,
                        prompt: item?.prompt,
                        id: item?.id,
                      });
                      setShowToolTip(false);
                      setIsEditModalOpen(true);
                      dispatch(lockSectionsApiCall({ identifier_name, section_name: item?.id ,file_name: getModuleSectionData?.file_name}));
                    }
                  }} />

                

              </div>
            </Tooltip> :
            <Tooltip title={<div className="locked-data">
              <div>Status : Locked</div>
              <div>Locked By : {item?.locked_by}</div>
              <div>Locked till : {timeRemainig}</div>
            </div>}placement="right">

              <LockOutlined onMouseOverCapture={()=> {getTimeUntilLocked(item?.locked_until)}}/>
            </Tooltip>
          }
        { item?.source && <Tooltip title="Source">
            <div className="" style={{marginTop:"10px"}}>
                <img src="/images/quick-ref.svg"
                  alt=""
                  onClick={()=> {
                    setShowModalOpen(true);
                    setOpenFile(item?.source);
                    // console.log(item?.source?.[0])
                  }
                  }

                />          
              </div>
              </Tooltip>}
        </div>}
     
      </div>
    ));
  };
  const handleCloseOrCancel = () => {
    if (showCompare) {
      setShowCompare(false);
    } else {
      resetState();
      setIsEditModalOpen(false);
setShowToolTip(true);
    }
  };
  return (
    <>
      <Modal
        title={!showCompare ? <div>
          <div className="title">Regenerate Module</div>
          <div className="horizontal-line"></div>

        </div> : <div>
          <div className="title">Compare Module</div>
          <div className="horizontal-line"></div>

        </div>}
        open={isEditModalOpen}
        maskClosable={false}
        className={!showCompare ? "edit-modal " : "compare-modal"}
        onClose={handleCloseOrCancel}
        onCancel={handleCloseOrCancel}
      >
        {!showCompare ?
          <>
            <div className="header-section">
              <div className="heading">Module 2</div>
              <div className="select-section">
                <span className="compare"> <img src="/images/compare-icon.svg" alt="" className="icon" onClick={() => setShowCompare(true)} />Compare </span>
                 Version
                <Select className="select-box" defaultValue={selectVersion} placeholder="Select Version" options={versionOptions} onChange={(e) => setSelectVersion(e)}>

                </Select></div>
            </div>

            <div className="edit-modal-sections">
            {!editPrompt &&  <Tooltip title="Edit Prompt">

                <div className="edit-option" onClick={() => {setEditPrompt(true);setEditContent(false)}}>
                  <img src="/images/edit-pen.svg"
                    alt="" />
                </div>
              </Tooltip>}

              <TextArea
                id="outlined-basic"
                // label="Heading 2"
                variant="outlined"
                value={editedSections?.prompt}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setEditedSections({
                    ...editedSections,
                    prompt: e.target.value,
                  })
                }
                className="textarea-prompt"
                disabled={!editPrompt}                 
              />
              {editPrompt && <div className="apply-buttons">
                <Button
                  className="btn-solid-transparent"
                  onClick={() => {setEditedSections({
                    ...editedSections,
                    prompt: sectionVersionsData?.section?.prompt || resetSections?.prompt,
                  });setEditPrompt(false);}}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-solid-blue"
                  onClick={() => updatePrompt()}
                >
                  Update
                </Button>
              </div>}



            </div>
            <div className="content-section-div">
            {!editContent &&  <Tooltip title="Edit Content">

                <div className="edit-option" onClick={() => {setEditContent(true);setEditPrompt(false)}}>
                  <img src="/images/edit-pen.svg"
                    alt="" /></div>
              </Tooltip>}
              <span
                className="content-section"
                onInput={(e) => setElementData(e.currentTarget.innerHTML)}
                style={{ padding: "20px" }}
                id="edited-content-id"
                contentEditable={editContent}
                dangerouslySetInnerHTML={{ __html: editedSections.content }}
              ></span>
            </div>
            {editContent && <div className="apply-buttons">
              <Button
                className="btn-solid-transparent"
                // onClick={() =>updateContent()}
                onClick={() => {
                  // resetState();
                  resetState();
                  // dispatch(selectVersionApiCall({ identifier_name, section_name: editedSections?.id, version: selectVersion || sectionVersionsData?.latest_version }))

                  // setSelectVersion(selectVersion)
                  //    setEditedSections({
                  //   ...editedSections,
                  //   content: resetSections?.content,
                  // });
                }
                }
              >
                Cancel
              </Button>
              <Button
                className="btn-solid-blue"
                onClick={() => updateContent()}
              >
                Update
              </Button>
            </div>}


          </> : <><div className="header-section">
            <div className="heading">Module 2</div>



          </div>

            <div className="compare-section">
              <div className="compare-section-1">
                <div className="select-section">
                  Select Version
                  <Select
                    className="select-box"
                    placeholder="Select Version"
                    defaultValue={selectCompareVersion1}
                    options={versionOptions}
                    onChange={(e) => setSelectCompareVersion1(e)}
                  />
                  <button onClick={(e) => { setSelectVersion(selectCompareVersion1); setShowCompare(false); }}>Select this version</button>
                </div>
                <div className="content" contentEditable="false">
                  <span dangerouslySetInnerHTML={{ __html: section1CompareData?.section?.content_html || 'Please Select Version' }}></span>
                </div>
              </div>

              <div className="compare-section-2">
                <div className="select-section">
                  Select Version
                  <Select
                    className="select-box"
                    placeholder="Select Version"
                    defaultValue={selectCompareVersion2}
                    options={versionOptions}
                    onChange={(e) => setSelectCompareVersion2(e)}
                  />
                  <button onClick={(e) => { setSelectVersion(selectCompareVersion2); setShowCompare(false); }}>Select this version</button>    </div>
                <div className="content" contentEditable="false" >
                  <span dangerouslySetInnerHTML={{ __html: section2CompareData?.section?.content_html || 'Please Select Version' }}></span>

                </div>

              </div>







            </div></>}
      </Modal>
      <Modal
        title="Source File"
        open={showModalOpen}
        maskClosable={false}
        className={!showCompare ? "edit-modal " : "compare-modal"}
        onCancel={() => {
          setShowModalOpen(false);
          setCurrentFile(0);
          setOpenFile();

        }}
      >
     {openFile?.map((e, idx) => (
        <div
          key={idx}
          onClick={() => changeFile(idx)}
          
          className={currentFile === idx ? "clickable-link-active" : "clickable-link"}
        >
          {idx+1}. {e?.name}
        </div>
      ))}
        
{openFile &&                   <PDFViewer  openFile={openFile?.[currentFile || 0]}  closeModal />
}
      </Modal>
      <Modal
        title="Basic Modal"
        open={isRegenerateModalIsOpen}
        maskClosable={false}
        className="regenerate-modal"
        onCancel={() => setIsRegenerateModalIsOpen(false)}
      >
        <div className="horizontal-line"></div>
        <div className="regenerate-modal-sections">data</div>
        <div className="apply-buttons">
          <Button
            className="btn-solid-black"
            // onClick={() => console.log(editedSections)}
          >
            Update
          </Button>
        </div>
      </Modal>
      <div className="summary-data">
        <div className="summary-data-content">
          <div className="btn-back" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className="arrow-icon" />
            Back
          </div>


          {!getModuleSectionDataLoading ?
           <>

            <div className="title-search">
              <span>Module 2 - {identifier_name}</span>
              {(getModuleSectionData?.data?.sections && downloading === false) ?
              <span className="options">
               { currentProgress === 100  && 
               <>
               <span     className="format-select" >
                  Select Format
                <Select
                  defaultValue="pdfDownload"
                  className="select-box"
                  value={selectedDownloadOption}
                  onChange={(e)=>setSelectedDownloadOption(e)}
                  
                  options={[
                    {
                      value: 'docxDownload',
                      label: 'Docx',
                    },
                    {
                      value: 'pdfDownload',
                      label: 'PDF',
                    },
                  ]}
                />
                </span>
                <span className="save-btn" onClick={() => sendToAPIAndDownloadPDF()}><img src="/images/download-icon.svg" alt="download-icon" />Download</span> 
                </>
              }
                {currentProgress < 100  &&
                 <ProgressBar
                 isOpen={showTooltip}
                bgcolor="#35D55D"
                progressStatus={currentProgress}
                // height={50}
              />}
              {
              (  generate && currentProgress < 100)  && <div><Tooltip title="Revise response"><ReloadOutlined onClick={()=>RefreshResponse()} classID="reload-icon" /></Tooltip></div>
              }
              
                
                {/*                 <span className="save-btn"><FileOutlined />Save as draft</span */}
                </span>:<span className="option"> {(getModuleSectionData?.data?.sections && downloading === true) ? <span className="format-select">File Loading ...</span> :""}</span>}
            </div>
            <div className="horizontal-line"></div>
            { (showLoader && !getModuleSectionDataLoading) && <div className="summary-data-content">
            <CustomLoader className="loader-section" />
            </div>}
            <div
              className="summarydata-section"
              id="summarydata-section"
              ref={summarySectionRef}
              style={{opacity: showLoader ? "0" :"1"}}
            
              // onMouseOver={(e)=>{
              //   if(e?.target?.id !== "" )
              //     {
              //       // setHandleKey(e?.target?.id)
              //       setDefaultOpenKey(String(e?.target?.id))
              //       setCurrentId(e?.target?.id);
              //       // console.log(e?.target?.id,"mouse pointer")
              //     }}}
              // onMouseOver={(e)=>{
              //   if(e?.target?.id !== "" )
              //     {
              //       // setHandleKey(e?.target?.id)
              //       setDefaultOpenKey(String(e?.target?.id))
              //       setCurrentId(e?.target?.id);
              //       console.log(e?.target?.id)
              //     }}}
              // onMouseOverCapture={(e)=>{
              //   if(e?.target?.id !== "" )
              //     {
              //       // setHandleKey(e?.target?.id)
              //       setDefaultOpenKey(String(e?.target?.id))
              //       setCurrentId(e?.target?.id);
              //       // console.log(e?.target?.id)
              //     }}}

            >
              {getModuleSectionData?.data ? 
              getModuleSectionData?.data?.sections?.map((section, index) => (
                <ScrollElement
                  key={section.id}
                  name={section.id}
                  className={section.class}
                  onScrollCapture={(e)=>{
                    if(e?.target?.id !== "" )
                      {
                        // setHandleKey(e?.target?.id)
                        // setDefaultOpenKey(String(e?.target?.id))
                        setCurrentId(e?.target?.id);
                      }}}
                      onMouseOver={(e)=>{
                        if(e?.target?.id !== "" )
                          {
                            // setHandleKey(e?.target?.id)
                            setDefaultOpenKey(String(e?.target?.id))
                            setCurrentId(e?.target?.id);
                            // console.log(e?.target?.id,"mouse pointer")
                          }}}

                >
                  {/* Uncomment if needed
      <div
        className={`heading-2 ${selectedOption === section.id ? "active" : ""}`}
      >
        {section.value || "-"}
      </div>
      */
     
      }

                  {section.children && renderSections(section.children)}
                </ScrollElement>
              )) : <div>
                {/* <div>Module is generating ...</div> */}
                <div>Please select a Section</div>
              </div>}
            </div>

          </> : 
          <div className="summary-data-content">
            <CustomLoader className="loader-section" />
            </div>}
        </div>
      </div>
    </>
  );
};

export default NewSummaryData;
