import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import "./App.scss";
import Sidebar from "./component/Sidebar";
import Home from "./pages/Home";
import SearchFolders from "./pages/SearchFolder";
import MyWorkspace from "./pages/WorkSpace";
import PDFViewer from "./component/PDFViewer";
import NewSummaryData from "./pages/NewSummarization";
import LoginPage from "./pages/Login";
import { useState } from "react";
import { getData, removeData } from "./utils/storageService";
import "react-toastify/dist/ReactToastify.min.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import NewSummarySideBar from "./component/NewSummarySideBar";

// Custom hook to check authentication
const useAuth = () => {
  const token = getData("Token");
  return !!token; 
};

// ProtectedRoute component with toast notification and logout functionality
const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useAuth();
  
  useEffect(() => {
    if (!isAuthenticated) {
      toast.error("Logout Successfull")
      removeData("Token");
      removeData("isLoggedIn");
      removeData("username");

    }
  }, [isAuthenticated ]);

  return isAuthenticated ? element : <Navigate to="/" />;
};

function App() {
  const [handleKey, setHandleKey] = useState();
  const [defaultOpenKey,setDefaultOpenKey]=useState();
  const [showLoader,setShowLoader]=useState(false)

  const location = useLocation();
  // const { isError, error } = useSelector((state) => state.apiStatus);

  const showSidebar = location.pathname !== "/";

  return (
    <div className="App">
      <ToastContainer className="toaster" />
     
      {showSidebar && <Sidebar setHandleKey={setHandleKey} handleKey={handleKey} defaultOpenKey={defaultOpenKey} setDefaultOpenKey={setDefaultOpenKey} setShowLoader={setShowLoader} showLoader={showLoader} />}
      <Routes>
      <Route path="/newsidebar" element={
         <NewSummarySideBar
      showLoader={showLoader}
      setShowLoader={setShowLoader} // Passing the setter function as a prop
      setHandleKey={setHandleKey}
      handleKey={handleKey}
      defaultOpenKey={defaultOpenKey}
      setDefaultOpenKey={setDefaultOpenKey}
    />} />

        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/result" element={<ProtectedRoute element={<SearchFolders />} />} />
        <Route path="/my-workspace" element={<ProtectedRoute element={<MyWorkspace />} />} />
        <Route path="/pdfviewer" element={<ProtectedRoute element={<PDFViewer />} />} />
        <Route path="/summary" element={<ProtectedRoute element={<NewSummaryData setHandleKey={setHandleKey} handleKey={handleKey} defaultOpenKey={defaultOpenKey} setDefaultOpenKey={setDefaultOpenKey} showLoader={showLoader} setShowLoader={setShowLoader} />} />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
