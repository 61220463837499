import { Flex, Spin } from 'antd';
import React from 'react'

const CustomLoader = () => {
    const contentStyle = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
      };
      const content = <div style={contentStyle} />;
  return (
    <div className="loader-section">
    <Flex gap="middle" vertical>
      <Flex gap="middle">
    
        <Spin tip="" size="large" className="loader">
          {content}
        </Spin>
      </Flex>
    
    </Flex>

  </div>  )
}

export default CustomLoader