import React, { useEffect, useState } from "react";
import "./homepage.scss";
import { AutoComplete, Form, Tooltip } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createNewProjectApiCall, getSearchOptionsApiCall } from "./logic";
import { useDispatch, useSelector } from "react-redux";
import apiClient from "../../utils/apiClient";

const Home = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSearchOptionsApiCall({ searchText }));
  }, [searchText, dispatch]);

  const { data: optionsDataList } = useSelector(
    (state) => state?.getSearchOptionsData
  );

  const autoCompleteOptions = [
    // Custom option with icon, only if searchText is valid and not in optionsDataList
    (searchText?.length > 2 && !optionsDataList?.suggestions?.some(item => item.identifier_name === searchText.trim())) && {
      value: searchText,
      label: (
        <div className="add-new" onClick={() => {dispatch(createNewProjectApiCall({ searchText }));navigate(`/result?search=${searchText}&status=New`);}}>
        <Tooltip title={searchText}><div className="search-text">{searchText}</div></Tooltip> <span className="text-icon" ><PlusCircleOutlined className="plus-icon" /> Add New</span>
        </div>
      ),
    },
    // Spread existing options from optionsDataList suggestions
    ...(optionsDataList?.suggestions?.map((item) => ({
      value: item.identifier_name,
      label: (
        <div className="auto-complete-option" onClick={()=>navigate(`/result?search=${item.identifier_name}&status=${item?.module_status}`)}>
          <span>{item?.identifier_name}</span>
          <span className="module-status">{item?.module_status}</span>
        </div>
      ),
    })) || []),
  ].filter(Boolean); // Filter out any falsy values like undefined or null

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <div className="home-page">
      <div className="home-page-content">
        <div className="title-1">AI eCTD generator</div>
        <div className="text-note">
          AI eCTD generator helps you create FDA compliant summary of various
          different modules in FDA with link to the original text of each module
          and our platform allows user to both store and edit these summaries.
        </div>
        <div className="search-box">
          <Form
            // onSubmitCapture={() => searchText?.length > 4 && navigate(`/result?search=${searchText}`)}
          >
            <AutoComplete
              className="search-bar"
              placeholder="Examples: Afatinib, Carboplatin, Osimertinib, etc"
              onChange={handleSearch}
              value={searchText}
              options={searchText?.length > 2 && autoCompleteOptions}

              // onSelect={(e) => searchText?.length > 4 && navigate(`/result?search=${e}`)}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Home;
