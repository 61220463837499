import * as React from "react";
import * as docx from "docx-preview";
import "./pdfViewer.scss";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import demopdf from "./demo.pdf";


function PDFViewer({ openFile, closeModal }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [fileType, setFileType] = React.useState(null);
  // console.log(openFile)

  // Update fileType when openFile changes
  React.useEffect(() => {
    if (openFile && openFile?.name) {
      const extension = openFile.name.split(".").pop().toLowerCase();
      setFileType(extension);
    }
  }, [openFile]);

  // Effect for rendering DOCX files
  React.useEffect(() => {
    if (fileType === "docx" && openFile?.url) {
      const panel = document.getElementById("panel-section");
      if (panel) {
        panel.innerHTML = ""; // Clear previous content

        fetch(openFile.url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            return response.arrayBuffer();
          })
          .then((buffer) => {
            docx.renderAsync(buffer, panel)
              .then(() => console.log("DOCX rendering finished"))
              .catch((error) => console.error("Error rendering DOCX:", error));
          })
          .catch((error) => console.error("Error fetching DOCX file:", error));
      }
    }
  }, [fileType, openFile]);

  return (
    <>
      {fileType === "pdf" ? (
        <div className="pdf-viewer">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
            <div className="pdf-render">
              <Viewer
                fileUrl={demopdf}
                plugins={[defaultLayoutPluginInstance]}
                defaultScale={0.9}
              />
            </div>
          </Worker>
        </div>
      ) : fileType === "docx" ? (
        <div
          className="panel-section"
          id="panel-section"
          style={{ height: "800px", overflowY: "auto" }} // Adjust style as needed
        >
          {/* DOCX rendering will be handled in the useEffect */}
        </div>
      ) : fileType === "doc" ? (
        <div className="doc-viewer">

        <DocViewer
        documents={[{ uri: openFile?.url, fileType: "doc" }]}
        pluginRenderers={DocViewerRenderers}
      />  
      </div>   ) : (
        <div>Unsupported file type</div>
      )}
    </>
  );
}

export default PDFViewer;